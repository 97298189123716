import { Button } from "@lib/Button";
import { Input } from "@lib/Input";
import { useResetPassword } from "@network/hooks/auth";

export const ResetPasswordScreen = () => {
  const { errors, handleChange, handleSubmit, isDisabled, isLoading, values } =
    useResetPassword();
  return (
    <div className="w-full h-[100vh] 2xl:h-[95vh]">
      <div
        className={`bg-hero_banner_3 bg-fixed w-full h-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <form
            onSubmit={handleSubmit}
            className="bg-white w-full md:w-1/2 rounded-xl overflow-hidden p-8"
          >
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-3xl font-bold text-center">Reset Password</h1>
              <p className="text-sm text-gray-400">
                Reset your account password.
              </p>
            </div>
            <div className="w-full mt-6">
              <Input
                label="Email Address"
                placeholder="Email Address"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email ?? ""}
                error={errors?.email}
              />
              <Input
                label="New Password"
                placeholder="New Password"
                type="password"
                name="newPassword"
                onChange={handleChange}
                value={values.newPassword ?? ""}
                error={errors?.newPassword}
              />
              <Input
                label="Confirm New Password"
                placeholder="Confirm New Password"
                type="password"
                name="confirmNewPassword"
                onChange={handleChange}
                value={values.confirmNewPassword ?? ""}
                error={errors?.confirmNewPassword}
              />
            </div>
            <Button
              label="Reset Password"
              rounded
              isLoading={isLoading}
              disabled={isDisabled}
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};
