import { InfoIcon, ProfileIcon2 } from "@assets/icons";
import { Button } from "@lib/Button";
import { Input } from "@lib/Input";
import { useSetupProfile } from "@network/hooks/auth";

export const SetupAccountScreen = () => {
  const steps = [
    {
      title: "My Details",
      icon: <ProfileIcon2 />,
    },
    {
      title: "Neighbour",
      icon: <ProfileIcon2 />,
    },
    {
      title: "Safe Place",
      icon: <ProfileIcon2 />,
    },
  ];

  const { errors, handleChange, handleSubmit, isDisabled, isLoading, values } =
    useSetupProfile();

  return (
    <div className="w-full min-h-[130vh]">
      <div
        className={`bg-hero_banner bg-fixed w-full min-h-[130vh] flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <form
            onSubmit={handleSubmit}
            className="bg-white w-full md:w-1/2 mx-auto rounded-xl overflow-hidden p-8"
          >
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-3xl font-bold text-center">
                Set up your account
              </h1>
              <div className="w-full flex items-center">
                {steps.map((step, _idx) => (
                  <div
                    key={_idx}
                    className={
                      _idx !== steps.length - 1
                        ? `w-full flex items-center`
                        : `flex items-center`
                    }
                  >
                    {/* Steps Display */}
                    <div className="relative flex flex-col items-center text-primary-500">
                      {/* Display Number */}
                      <div
                        className={`rounded-full transition duration-500 ease-in-out border-2 border-primary-500 h-12 w-12 flex items-center justify-center py-3 "bg-primary-500 text-white font-bold  border-primary-500"`}
                      >
                        {/* icon */}
                        {step.icon}
                      </div>
                      {/* Display Description */}
                      <div
                        className={`absolute top-0 text-center mt-14 w-32 text-xs font-medium uppercase text-gray-400`}
                      >
                        {step.title}
                      </div>
                    </div>
                    <div
                      className={`flex-auto border-t-2 transition duration-500 ease-in-out border-primary-500`}
                    >
                      {/* Display Horizontal Line */}
                    </div>
                  </div>
                ))}
              </div>
              <p className="text-base text-gray-400 text-center mb-5 mt-12">
                Enter your delivery address to add delivery preferences to, and
                each time we deliver to this address we will automatically apply
                your preferences
              </p>
            </div>
            <div className="w-full">
              <Input
                label="Address"
                placeholder="Your Address"
                name="address"
                onChange={handleChange}
                value={values.address}
                error={errors?.address}
              />
              <Input
                label="Postal Code"
                placeholder="Your Postal Code"
                name="postal_code"
                onChange={handleChange}
                value={values.postal_code}
                error={errors?.postal_code}
              />
              <Input
                label="Phone Number"
                placeholder="Your Phone Number"
                name="phone"
                pattern="[0-9]*"
                inputMode="numeric"
                type="number"
                onChange={handleChange}
                value={values.phone}
                error={errors?.phone}
              />
              <div className="flex items-center justify-center text-gray-600 gap-5 w-[80%] mx-auto">
                <div className="w-5">
                  <InfoIcon />
                </div>
                <p className="text-sm text-gray-400 mb-4">
                  Your mobile number helps ensure your parcels appear in your
                  account and aid delivery.
                </p>
              </div>
            </div>

            <Button
              label="Next"
              rounded
              isLoading={isLoading}
              disabled={isDisabled}
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};
