import { DocumentIcon, ParcelIcon } from "@assets/icons";
import { Input } from "@lib/Input";
import { Spinner } from "@lib/Loader";
import { useGetShipmentPrice } from "@network/hooks/shipment";
import { ICreateShipment } from "@network/interface";
import { FormikErrors } from "formik";
import { useEffect } from "react";

interface IProps {
  handleChange: any;
  values: ICreateShipment;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  errors: FormikErrors<ICreateShipment>;
}

export const ShipmentDetailsForm = ({
  errors,
  handleChange,
  values,
  setFieldValue,
}: IProps) => {
  const { isLoading, estimate } = useGetShipmentPrice({
    country: values.receiver_country,
    delivery: values.receiver_postal_code,
    pickup: values.shipper_postal_code,
    weight: values.weight,
  });

  useEffect(() => {
    setFieldValue("total_amount", estimate);
  }, [estimate]);

  
  const handleNumberChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    if (isNaN(value)) {
      return;
    } else {
      handleChange({
        target: { name, value },
      });
    }
  };

  return (
    <div>
      <div className="mt-5">
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-[48%]">
            <Input
              label="Shipment Content"
              placeholder="clothes and bag"
              required
              name="shipment_content"
              onChange={handleChange}
              value={values.shipment_content}
              error={errors?.shipment_content}
            />
          </div>
          <div className="w-full md:w-[48%]">
            <Input
              label="Total gross weights"
              placeholder="45kg"
              required
              min={1}
              disabled
              type="number"
              name="weight"
              // onChange={handleChange}
              value={values.weight}
              error={errors?.weight}
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-[48%]">
            <Input
              label="Weight"
              placeholder="55kg"
              required
              min={1}
              type="number"
              name="weight"
              onChange={handleNumberChange}
              value={values.weight}
              error={errors?.weight}
            />
          </div>
          <div className="w-full md:w-[48%]">
            <Input
              label="Number of Pieces"
              placeholder="14"
              min={1}
              required
              name="number_of_pieces"
              type="number"
              onChange={handleNumberChange}
              value={values.number_of_pieces}
              error={errors?.number_of_pieces}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 mb-4 items-center">
          <div className="flex items-center justify-center gap-5 my-8">
            <div
              onClick={() => {
                setFieldValue("parcel_type", "DOCUMENT");
              }}
              className={`w-[8rem] md:w-[10rem] h-[12rem] md:h-[13rem] p-3 md:p-8 rounded-md shadow-lg flex flex-col items-center justify-center gap-3 cursor-pointer text-gray-400 ${
                values.parcel_type === "DOCUMENT"
                  ? "border border-primary-500 shadow-primary-500 text-primary-500"
                  : ""
              }`}
            >
              <p>Document(s)</p>
              <DocumentIcon />
            </div>
            <div
              onClick={() => {
                setFieldValue("parcel_type", "PARCEL");
              }}
              className={`w-[8rem] md:w-[10rem] h-[12rem] md:h-[13rem] p-3 md:p-8 rounded-md shadow-lg flex flex-col items-center justify-center gap-3 cursor-pointer text-gray-400 ${
                values.parcel_type === "PARCEL"
                  ? "border border-primary-500 shadow-primary-500 text-primary-500"
                  : ""
              }`}
            >
              <p>Parcel</p>
              <ParcelIcon />
            </div>
          </div>
          {errors?.parcel_type ? (
            <small className="text-sm text-center text-red-300">
              {errors?.parcel_type}
            </small>
          ) : (
            ""
          )}
          <div className="border border-gray-400 bg-gray-300 rounded-lg w-full flex items-center justify-center p-5">
            {isLoading ? (
              <Spinner />
            ) : (
              <p className="text-lg">
                <span className="font-bold text-black uppercase mr-2">
                  Total Price (£):
                </span>
                £{estimate?.toLocaleString()}
              </p>
            )}
          </div>
        </div>
        <p className="text-sm text-gray-400">
          Please ensure weight is accurate to avoid any delays caused by weight
          difference. Price may change in case weight was different.
        </p>
      </div>
    </div>
  );
};
