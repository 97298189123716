import { Button } from "@lib/Button";
import { Input } from "@lib/Input";
import { useLogin } from "@network/hooks/auth";
import { Link } from "react-router-dom";

export const LoginScreen = () => {
  const { errors, handleChange, handleSubmit, isDisabled, isLoading, values } =
    useLogin();
  return (
    <div className="w-full h-[100vh] 2xl:h-[95vh]">
      <div
        className={`bg-hero_banner_3 bg-fixed w-full h-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <form
            onSubmit={handleSubmit}
            className="bg-white w-full md:w-1/2 rounded-xl overflow-hidden p-8"
          >
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-3xl font-bold text-center">Log In</h1>
              <p className="text-sm text-gray-400">
                Access your Kadaa deliveries by logging in.
              </p>
            </div>
            <div className="w-full mt-6">
              <Input
                label="Email Address"
                placeholder="Email Address"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                error={errors?.email}
              />
              <Input
                label="Password"
                placeholder="Password"
                type="password"
                name="password"
                onChange={handleChange}
                value={values.password}
                error={errors?.password}
              />
            </div>
            <div className="my-3 flex items-center justify-end">
              <p className="text-gray-400">
                <Link
                  to="/auth/forgot-password"
                  className="text-primary-500 ml-3"
                >
                  Forgot Password
                </Link>
              </p>
            </div>
            <div className="my-10 flex items-center justify-center">
              <p className="text-gray-400">
                Don't have an account?
                <Link to="/auth/register" className="text-primary-500 ml-3">
                  Sign Up
                </Link>
              </p>
            </div>
            <Button
              label="Log In"
              rounded
              isLoading={isLoading}
              disabled={isDisabled}
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};
