import { Spinner } from "@lib/Loader";
import { useConfirmPayment } from "@network/hooks/shipment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const PaymentCompletedScreen = () => {
  const navigate = useNavigate();

  const { isLoading, success, error } = useConfirmPayment();

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate("/dashboard", { replace: true });
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <div className="bg-gray-100 h-[70vh] w-full flex items-center justify-center">
      <div className="bg-white rounded-md flex gap-8 flex-col items-center justify-center px-3 py-20 w-full xl:w-1/4">
        {isLoading ? (
          <div className="w-full flex flex-col items-center justify-center">
            <Spinner />
            <p className="text-lg text-center text-primary-500 font-bold">
              Please hold on while we confirm your payment.
            </p>
          </div>
        ) : success ? (
          <>
            <p className="text-3xl text-center text-primary-500 font-bold">
              Payment Confirmed Successfully
            </p>
            <p className="text-lg text-center text-gray-500">Redirecting...</p>
          </>
        ) : error ? (
          <p className="text-2xl text-center text-red-500 font-bold">
            <span className="underline text-lg">
              Unable to verify Payment:{" "}
            </span>
            <br />
            {error}
          </p>
        ) : null}
      </div>
    </div>
  );
};
