import { Button } from "@lib/Button";
import { CheckBox } from "@lib/Checkbox";
import { Input } from "@lib/Input";
import { useRegisterWithEmail } from "@network/hooks/auth";
import { Link } from "react-router-dom";

export const RegisterScreen = () => {
  const { errors, handleChange, handleSubmit, isDisabled, isLoading, values } =
    useRegisterWithEmail();

  return (
    <div className="w-full min-h-[100vh] 2xl:min-h-[95vh]">
      <div
        className={`bg-hero_banner_3 bg-fixed w-full min-h-[100vh] flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <form
            onSubmit={handleSubmit}
            className="bg-white w-full md:w-[65%] my-8 rounded-xl overflow-hidden p-8"
          >
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-3xl font-bold">Sign Up</h1>
              <p className="text-sm text-gray-400">
               Register an account and take control of all your Kadaa deliveries.
              </p>
            </div>
            <div className="w-full mt-6">
              <Input
                label="Name"
                placeholder="Your Full Name"
                name="fullName"
                onChange={handleChange}
                value={values.fullName}
                error={errors?.fullName}
              />
              <Input
                label="Email Address"
                placeholder="Your Email Address"
                name="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                error={errors?.email}
              />
              <Input
                label="Phone Number"
                placeholder="Your Phone Number"
                type="number"
                name="phoneNumber"
                pattern="[0-9]*"
                inputMode="numeric"
                onChange={handleChange}
                value={values.phoneNumber}
                error={errors?.phoneNumber}
              />
              <Input
                label="Password"
                placeholder="Create a strong password"
                name="password"
                type="password"
                onChange={handleChange}
                value={values.password}
                error={errors?.password}
              />
              <CheckBox
                label="I wish to receive newsletters, updates and information via email."
                htmlFor="receive_news_letter"
                name="receive_news_letter"
                onChange={handleChange}
                checked={values.receive_news_letter}
                error={errors?.receive_news_letter}
              />
              <CheckBox
                label="I would like appreciate receiving updates and special offers from Kadaa's partners via email."
                htmlFor="receive_offer_letter"
                name="receive_offer_letter"
                onChange={handleChange}
                checked={values.receive_offer_letter}
                error={errors?.receive_offer_letter}
              />
              <CheckBox
                label="I agree to the terms and conditions as weel as the privacy statement for this account."
                htmlFor="terms_and_conditions"
                name="accept_terms_and_conditions"
                onChange={handleChange}
                checked={values.accept_terms_and_conditions}
                error={errors?.accept_terms_and_conditions}
              />
            </div>
            <div className="my-10 flex items-center justify-center">
              <p className="text-gray-400">
                Already have an account?
                <Link to="/auth/login" className="text-primary-500 ml-3">
                  Log in
                </Link>
              </p>
            </div>
            <Button
              label="Sign Up"
              rounded
              type="submit"
              isLoading={isLoading}
              disabled={isDisabled}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
