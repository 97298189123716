import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  required?: boolean;
  name?: string;
  useGooglePlaces?: boolean;
  suffix?: React.ReactElement;
  setFieldValue?: any;
  touched?: boolean;
}

export const Input = ({
  label,
  error,
  required,
  name,
  useGooglePlaces,
  suffix,
  setFieldValue,
  touched,
  ...props
}: IProps) => {
  return (
    <div className="w-full mb-4">
      {label ? (
        <label className="text-gray-400 flex items-center">
          {label}
          {required ? <span className="text-primary-500">*</span> : null}
        </label>
      ) : null}
      {useGooglePlaces ? (
        <GooglePlacesAutocomplete
          apiKey="AIzaSyBkb4AVtMIc_2SDrgwow-lSHe9yfYpsnFs"
          selectProps={{
            onChange: (val) => setFieldValue(name, val?.value?.description),
            components: {
              DropdownIndicator: () => null,
              LoadingMessage: () => (
                <div className="py-2 text-center flex items-center justify-center text-gray-400">
                  Searching...
                </div>
              ),
              IndicatorSeparator: () => null,
            },
            styles: {
              input: (base, prop) => ({
                ...base,
                padding: "0.56rem",
                borderColor: "rgb(156 163 175)",
                color: "rgb(0 0 0)",
                borderRadius: "0.375rem",
              }),
            },
          }}
        />
      ) : (
        <div className="w-full flex gap-2 items-center justify-between">
          <input
            name={name}
            className="w-full p-3 rounded-md border border-gray-400 placeholder:text-gray-300 text-black"
            {...props}
          />
          {suffix ? suffix : null}
        </div>
      )}
      {error ? (
        <small className="block text-red-300 text-sm">{error}</small>
      ) : null}
    </div>
  );
};
