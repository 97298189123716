import { PaymentModal } from "@components/Modal/PaymentModal";
import useDisclosure from "@hooks/useDisclosure";
import { Stepper, StepperControl } from "@lib/Stepper";
import { useCreateShipment } from "@network/hooks/shipment";
import { useState } from "react";
import {
  PaymentOptions,
  ReceiverDetailsForm,
  ShipmentDetailsForm,
  ShipperDetailsForm,
} from "./steps";
import { toast } from "react-hot-toast";
import { PaymentConfirmationModal } from "./modals/PaymentConfirmationModal";

export const ShipPacelScreen = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: paymentIsOpen,
    onClose: paymentOnClose,
    onOpen: paymentOnOpen,
  } = useDisclosure();

  const [hasConfirmed, setHasConfirmed] = useState(false);

  const [submitType, setSubmitType] = useState<"draft" | "proceed">("draft");

  const steps = [
    "Shipper Details",
    "Receiver Details",
    "Shipment Details",
    "Payment Options",
  ];

  const {
    errors,
    handleChange,
    handleSubmit,
    isDisabled,
    isLoading,
    setFieldValue,
    values,
    receiverStreetOptions,
    shipperStreetOptions,
    parcel,
    isLoadingReceiverStreets,
    isLoadingShipperStreets,
  } = useCreateShipment(
    submitType === "proceed"
      ? () => {
          onClose();
          paymentOnOpen();
        }
      : undefined
  );

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    const capitalizedValue = value.toUpperCase();
    handleChange({
      target: { name, value: capitalizedValue },
    });
  };

  const displayStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <ShipperDetailsForm
            errors={errors}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            values={values}
            streets={shipperStreetOptions}
            isLoadingShipperStreets={isLoadingShipperStreets}
            setFieldValue={setFieldValue}
          />
        );

      case 2:
        return (
          <ReceiverDetailsForm
            errors={errors}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            values={values}
            isLoadingReceiverStreets={isLoadingReceiverStreets}
            streets={receiverStreetOptions}
            setFieldValue={setFieldValue}
          />
        );

      case 3:
        return (
          <ShipmentDetailsForm
            errors={errors}
            handleChange={handleChange}
            values={values}
            setFieldValue={setFieldValue}
          />
        );

      case 4:
        return (
          <PaymentOptions
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
          />
        );
    }
  };

  const handleClick = (
    direction: "next" | "prev",
    type?: "proceed" | "draft"
  ) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;

    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);

    if (currentStep === steps.length + 1 - 1 && direction === "next") {
      setSubmitType(type ? type : "draft");
      if (type === "draft") {
        handleSubmit();
      } else if (values.total_amount && type === "proceed") {
        onOpen();
      } else {
        toast.error("Failed to generate payment amount!.", { id: "error" });
      }
    }
  };

  const checkCanGoNext = () => {
    if (
      currentStep === 1 &&
      values.shipper_email_address !== "" &&
      values.shipper_fullname !== "" &&
      values.shipper_country !== "" &&
      values.shipper_phone_number !== "" &&
      values.shipper_postal_code !== "" &&
      values.shipper_street_name !== ""
    ) {
      return true;
    } else if (
      currentStep === 2 &&
      values.receiver_email_address !== "" &&
      values.receiver_fullname !== "" &&
      values.receiver_country !== "" &&
      values.receiver_phone_number !== "" &&
      values.receiver_postal_code !== "" &&
      values.receiver_street_name !== ""
    ) {
      return true;
    } else if (
      currentStep === 3 &&
      values.shipment_content !== "" &&
      values.weight !== "" &&
      values.number_of_pieces !== "" &&
      values.parcel_type !== ""
    ) {
      return true;
    } else if (currentStep === 4 && values.preferred_payment_method !== "") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="w-full">
      <div
        className={`bg-hero_banner_4 bg-fixedt w-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto mt-3 md:mt-[10rem] mb-[2rem]">
          <div className="bg-white w-full md:w-[65%] mx-auto rounded-xl overflow-hidden p-4 md:p-8">
            {/* stepper */}
            <div className="w-full mt-5">
              <Stepper steps={steps} currentStep={currentStep} />
              <div className="my-10">{displayStep(currentStep)}</div>
            </div>
            <StepperControl
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
              isLoading={isLoading}
              disabled={isDisabled}
              canGoNext={!checkCanGoNext()}
              type={submitType}
            />
          </div>
        </div>
      </div>
      <PaymentConfirmationModal
        isLoadingConfirm={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          setSubmitType("proceed");
          setHasConfirmed(true);
          handleSubmit();
        }}
      />
      <PaymentModal
        isOpen={paymentIsOpen}
        onClose={paymentOnClose}
        amount={Number(values.total_amount)}
        parcel_id={parcel?._shipment_id}
        isCreate={submitType === "proceed" && hasConfirmed}
        shipmentId={parcel?._id}
      />
    </div>
  );
};
