import { Modal } from "@components/Modal";
import { Button } from "@lib/Button";

export const PaymentConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoadingConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoadingConfirm: boolean;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full border-b border-b-gray-300 p-3 text-xl font-bold">
        Are you sure you want to pay now?.
      </div>
      <div className="p-10 bg-gray-200 text-black text-center font-bold">
        Would you like to proceed with the payment immediately? This means that
        the transaction will be processed right away, and the payment will be
        charged to your account or card.
      </div>
      <div className="w-full border-t border-t-gray-300 p-3">
        <div className="w-full flex gap-4 mt-4 justify-end">
          <Button
            label="Cancel"
            className={`!bg-transparent !border !border-solid !border-red-500 hover:border-red-600 !text-red-500 py-4`}
            onClick={() => {
              onClose();
            }}
          />
          <Button
            label="Confirm"
            isLoading={isLoadingConfirm}
            className={`!bg-transparent !border !border-solid !border-primary-500 hover:border-primary-600 !text-primary-500 py-4`}
            onClick={() => {
              onConfirm();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
