import { IUser } from "@app/interfaces/auth";
import { createSlice } from "@reduxjs/toolkit";

const userInitState: IUser = {
  access_token: "",
  user: {
    __v: 0,
    _account_id: "",
    _id: "",
    account_verified: false,
    avatar: "",
    createdAt: "",
    email: "",
    fullName: "",
    is_email_verified: false,
    phoneNumber: "",
    address: "",
    postal_code: "",
    token: "",
    updatedAt: "",
    verification_code: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: userInitState,
  reducers: {
    updateUser: (state, action: { payload: Partial<IUser> }) => {
      return { ...state, ...action.payload };
    },
    updateUserEmail: (state, action: { payload: { email: string } }) => {
      return { ...state, user: { ...state.user, email: action.payload.email } };
    },
    logoutUser: (state) => {
      return userInitState;
    },
  },
});

export const { updateUser, logoutUser, updateUserEmail } = userSlice.actions;
export default userSlice.reducer;
