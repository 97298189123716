import { logoutUser } from "@app/index";
import { ChevronDownArrow, Logo, ProfileIcon } from "@assets/icons";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { Button } from "@lib/Button";
import { CloseOutlined } from "@mui/icons-material";
import { access } from "fs";
import { useEffect, useRef, useState } from "react";
import { Link as RLink, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { access_token, user } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();

  const [showNotificaitonDropdown, setShowNotificationDropdown] =
    useState(false);

  const [showMenu, setShowMenu] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);

  const userTrigger = useRef<any>(null);
  const userDropdown = useRef<any>(null);
  const notificationTrigger = useRef<any>(null);
  const notificationDropdown = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!userDropdown.current) return;
      if (
        !showUserDropdown ||
        userDropdown.current.contains(target) ||
        userTrigger.current.contains(target)
      )
        return;
      setShowUserDropdown(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!showUserDropdown || keyCode !== 27) return;
      setShowUserDropdown(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!notificationDropdown.current) return;
      if (
        !showNotificaitonDropdown ||
        notificationDropdown.current.contains(target) ||
        notificationTrigger.current.contains(target)
      )
        return;
      setShowNotificationDropdown(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!showNotificaitonDropdown || keyCode !== 27) return;
      setShowNotificationDropdown(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleLogoutUser = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    const body = document.body;

    if (showMenu) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [showMenu]);

  return (
    <div className="w-full py-2 bg-white shadow-xl sticky top-0 left-0 z-[1000]">
      <div className="flex items-center justify-between w-[90%] 2xl:w-[60%] mx-auto">
        <RLink to="/?page=send">
          <Logo />
        </RLink>
        <nav className="hidden md:flex items-center justify-center gap-9 xl:w-[32%] 2xl:w-[45%] md:w-[60%]">
          <RLink
            to="/"
            className={
              pathname === "/"
                ? `text-base cursor-pointer text-primary-500`
                : `text-base cursor-pointer text-gray-400`
            }
          >
            Home
          </RLink>

          <RLink
            to="/about"
            className={
              pathname === "/about"
                ? `text-base cursor-pointer text-primary-500 block`
                : `text-base cursor-pointer text-gray-400 block`
            }
          >
            About
          </RLink>
          <RLink
            to="/our-goals"
            className={
              pathname === "/our-goals"
                ? `text-base cursor-pointer text-primary-500 block`
                : `text-base cursor-pointer text-gray-400 block`
            }
          >
            Our Goal
          </RLink>
        </nav>

        <div className="hidden md:flex items-center gap-5">
          {access_token ? (
            <>
              <div className="relative">
                <div
                  className="bg-primary-200 rounded-md px-3 py-2 flex items-center justify-between gap-4 cursor-pointer"
                  ref={userTrigger}
                  onClick={() => setShowUserDropdown((prev) => !prev)}
                >
                  <p>{user?.fullName}</p>
                  <ChevronDownArrow className="rotate-180" />
                </div>
                <div
                  ref={userDropdown}
                  onFocus={() => setShowUserDropdown(true)}
                  onBlur={() => setShowUserDropdown(false)}
                  className={`max-w-[20rem] min-w-[14rem] absolute top-0 mt-5 right-0 z-[99999] bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 hidden ${
                    showUserDropdown ? "!block" : ""
                  }`}
                >
                  <div className="px-2">
                    {access_token ? (
                      <div>
                        <RLink
                          onClick={() => setShowMenu(false)}
                          to="/dashboard"
                          className={
                            pathname === "//dashboard"
                              ? `text-base my-3 cursor-pointer text-primary-500 block`
                              : `text-base my-3 cursor-pointer text-gray-400 block`
                          }
                        >
                          My Shipments
                        </RLink>
                        <RLink
                          onClick={() => setShowMenu(false)}
                          to="/dashboard/invoices"
                          className={
                            pathname === "/dashboard/invoices"
                              ? `text-base my-3 cursor-pointer text-primary-500 block`
                              : `text-base my-3 cursor-pointer text-gray-400 block`
                          }
                        >
                          My Invoices
                        </RLink>
                        <RLink
                          onClick={() => setShowMenu(false)}
                          to="/settings"
                          className={
                            pathname === "/settings"
                              ? `text-base my-3 cursor-pointer text-primary-500 block`
                              : `text-base my-3 cursor-pointer text-gray-400 block`
                          }
                        >
                          Profile Settings
                        </RLink>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="flex items-center justify-between px-2 py-4 cursor-pointer"
                    onClick={() => handleLogoutUser()}
                  >
                    <p className="text-red-400 text-sm">Logout</p>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <RLink
            to={access_token ? "/dashboard" : "/auth/login"}
            className="hidden md:block"
          >
            <ProfileIcon className="text-primary-500 cursor-pointer" />
          </RLink>
        </div>
        <div className="block md:hidden">
          <ProfileIcon
            className="text-primary-500 cursor-pointer"
            onClick={() => setShowMenu(true)}
          />
        </div>
        <nav
          className={twMerge(
            "md:hidden fixed top-0 z-2 left-0 bg-white w-screen h-full",
            showMenu ? "block" : "hidden"
          )}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="flex items-center gap-4 w-full border-b p-4 border-b-gray-400">
              <CloseOutlined
                className="cursor-pointer"
                onClick={() => setShowMenu(false)}
              />
              <RLink to="/?page=send" onClick={() => setShowMenu(false)}>
                <Logo />
              </RLink>
            </div>
            <div className="w-full px-8 mt-4 h-full">
              <RLink
                onClick={() => setShowMenu(false)}
                to="/"
                className={
                  pathname === "/"
                    ? `text-base my-3 cursor-pointer text-primary-500`
                    : `text-base my-3 cursor-pointer text-gray-400`
                }
              >
                Home
              </RLink>

              <RLink
                onClick={() => setShowMenu(false)}
                to="/about"
                className={
                  pathname === "/about"
                    ? `text-base my-3 cursor-pointer text-primary-500 block`
                    : `text-base my-3 cursor-pointer text-gray-400 block`
                }
              >
                About
              </RLink>
              <RLink
                onClick={() => setShowMenu(false)}
                to="/our-goals"
                className={
                  pathname === "/our-goals"
                    ? `text-base my-3 cursor-pointer text-primary-500 block`
                    : `text-base my-3 cursor-pointer text-gray-400 block`
                }
              >
                Our Goal
              </RLink>
              {access_token ? (
                <div>
                  <RLink
                    onClick={() => setShowMenu(false)}
                    to="/dashboard"
                    className={
                      pathname === "//dashboard"
                        ? `text-base my-3 cursor-pointer text-primary-500 block`
                        : `text-base my-3 cursor-pointer text-gray-400 block`
                    }
                  >
                    My Shipments
                  </RLink>
                  <RLink
                    onClick={() => setShowMenu(false)}
                    to="/dashboard/invoices"
                    className={
                      pathname === "/dashboard/invoices"
                        ? `text-base my-3 cursor-pointer text-primary-500 block`
                        : `text-base my-3 cursor-pointer text-gray-400 block`
                    }
                  >
                    My Invoices
                  </RLink>
                </div>
              ) : null}
              {access_token ? (
                <div className="pt-3 border-t border-t-gray-400">
                  <p className="font-bold text-gray-800">User Name</p>
                  <RLink
                    onClick={() => setShowMenu(false)}
                    to="/settings"
                    className={
                      pathname === "/settings"
                        ? `text-base my-3 cursor-pointer text-primary-500 block`
                        : `text-base my-3 cursor-pointer text-gray-400 block`
                    }
                  >
                    Profile Settings
                  </RLink>
                  <div
                    className="flex items-center justify-between px-2 py-4 cursor-pointer"
                    onClick={() => handleLogoutUser()}
                  >
                    <p className="text-red-400 text-sm">Logout</p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mb-3 px-8">
              {!access_token ? (
                <div className="flex gap-5 items-center justify-between my-5">
                  <Button
                    label="Login"
                    onClick={() => {
                      setShowMenu(false);
                      navigate("/auth/login");
                    }}
                  />
                  <Button
                    label="Create Account"
                    onClick={() => {
                      setShowMenu(false);
                      navigate("/auth/register");
                    }}
                  />
                </div>
              ) : null}
              <Button
                label="Create Shipment"
                onClick={() => {
                  setShowMenu(false);
                  navigate(access_token ? "/ship-parcel" : "/auth/login");
                }}
              />
              <div className="mt-5 w-full">
                <Button
                  label="Track Shipment"
                  onClick={() => {
                    setShowMenu(false);
                    navigate("/track-parcel");
                  }}
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
