import { TickCircle } from "@assets/images";
import { Button } from "@lib/Button";
import { Spinner } from "@lib/Loader";
import { Status, useVerifyAccount } from "@network/hooks/auth";

export const VerifyingAccountScreen = () => {
  const { isLoading, status, error_message } = useVerifyAccount();

  return (
    <div className="w-full h-[100vh] 2xl:h-[95vh]">
      <div
        className={`bg-hero_banner_3 bg-fixed w-full h-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <div className="bg-white w-full md:w-1/2 mx-auto rounded-xl overflow-hidden p-8 shadow-2xl">
            {status === Status.SUCCESS ? (
              <div className="flex items-center justify-center flex-col">
                <img src={TickCircle} alt="success" className="w-20 h-20" />
                <div className="flex justify-center">
                  <p>Redirecting ... </p>
                </div>
              </div>
            ) : status === Status.ERROR ? (
              <div className="flex items-center justify-center flex-col">
                {/* <img src={TickCircle} alt="success" className="w-20 h-20" /> */}
                <h2 className="text-xl font-bold text-center text-red-500 my-4">
                  Failed to verify your account
                </h2>
                <div className="flex justify-center text-red-400">
                  <p>Reason: {error_message} </p>
                </div>
                <div
                  className={`flex justify-center transition duration-300 my-7 ${
                    // countDown > 0
                    false
                      ? "opacity-[0.3] text-gray-400 cursor-not-allowed"
                      : "cursor-pointer text-primary-500 opacity-1"
                  }`}
                >
                  {/* <p>
                    RETRY {countDown > 0 ? <span>({countDown})</span> : null}
                  </p> */}
                </div>
                <Button label={`Resend Verification Email`} />
              </div>
            ) : (
              <>
                <div className="animate-bounce flex items-center justify-center my-10">
                  {isLoading ? (
                    <Spinner className="text-primary-500 h-12 w-12" />
                  ) : null}
                </div>
                <p className="text-lg text-center">
                  Hold on while we verify your account
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
