import { saveQuoteDetails } from "@app/index";
import { TruckIcon } from "@assets/icons";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { Button } from "@lib/Button";
import { Spinner } from "@lib/Loader";
import { useGetQuote } from "@network/hooks/shipment";
import { useNavigate } from "react-router-dom";

export const GetQuoteScreen = () => {
  const navigate = useNavigate();
  const { isLoading, delivery, pickup, weight, estimate, country } =
    useGetQuote();
  const dispatch = useAppDispatch();

  return (
    <div className="w-full h-[100vh]">
      <div
        className={`bg-hero_banner_3 w-full h-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <div className="bg-white w-full md:w-1/2 mx-auto rounded-xl overflow-hidden p-8">
            <h1 className="text-xl font-bold text-center">Shipping Summary</h1>
            <div className="flex justify-between items-center my-8 flex-col lg:flex-row">
              <p className="w-full lg:w-[30%] text-center">
                Pickup Postal Code:{" "}
                <span className="font-medium text-xl">{pickup}</span>
              </p>
              <div className="flex items-center justify-between w-full my-4 lg:my-0 lg:w-[30%]">
                <div className="w-[45%] border-t border-t-gray-300 border-dotted" />
                <TruckIcon />
                <div className="w-[45%] border-t border-t-gray-300 border-dotted" />
              </div>
              <p className="w-full lg:w-[30%] text-center">
                Delivery Postal Code:{" "}
                <span className="font-medium text-xl">{delivery}</span>
              </p>
            </div>
            <div className="flex items-center justify-between my-8">
              <div>
                <p>Weight: {weight} KG</p>
              </div>
              <div>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <p>
                    {estimate === ""
                      ? "£0.00 (Incl VAT)"
                      : `£${estimate?.toLocaleString()} (Incl VAT)`}
                  </p>
                )}
              </div>
            </div>
            <Button
              label="Ship Item"
              rounded
              onClick={() => {
                dispatch(
                  saveQuoteDetails({ delivery, weight, pickup, country })
                );
                navigate("/ship-parcel");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
