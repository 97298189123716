import { EmailIcon, LocationIcon, Logo, PhoneIcon } from "@assets/icons";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="w-full">
      <div className="w-[90%] 2xl:w-[60%] mx-auto py-10">
        <div className="flex items-center justify-between flex-col xl:flex-row">
          {/* left */}
          <div className="w-full xl:w-[60%] flex flex-col xl:flex-row items-start xl:items-center gap-5 md:gap-10">
            <div>
              <Logo />
            </div>
            <div className="xl:max-w-[20rem]">
              <p className="text-sm md:text-base">
                At Kadaa Limited, we are committed to promoting sustainability
                in everything we do. As a logistics company, we recognize that
                our operations have an impact on the environment
              </p>
            </div>
          </div>
          {/* right */}
          <div className="w-full 2xl:w-[40%] xl:w-[50%] mt-8 xl:mt-0 flex flex-col">
            <div className="flex items-center justify-between md:flex-row flex-col">
              <div className="w-full md:w-auto flex items-center gap-2">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-primary-500">
                  <PhoneIcon />
                </div>
                <div>
                  <p className="text-base font-bold">Call Us</p>
                  <p className="text-sm text-gray-400">02039377735</p>
                </div>
              </div>
              <div className="w-full md:w-auto mt-4 md:mt-0 flex items-center gap-2">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-primary-500">
                  <EmailIcon />
                </div>
                <div>
                  <p className="text-base font-bold">Email</p>
                  <p className="text-sm text-gray-400">info@kadaalimited.com</p>
                </div>
              </div>
            </div>
            <div className="my-8 flex gap-4 items-center">
              <div>
                <LocationIcon />
              </div>
              <p className="text-sm">
                Rear of, Scenic Building, <br />
                214 Crayford way, <br />
                Dartford DA1 4LR, <br /> United kingdom.
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <a
            href="https://www.linkedin.com/company/93865671"
            rel="noreferrer"
            target="_blank"
          >
            <LinkedIn
              className="block text-primary-500 hover:text-primary-600 duration-300 transition"
              fontSize="large"
            />
          </a>
          <a
            href="https://instagram.com/kadaalimited?igshid=MzRIODBiNWFIZA=="
            rel="noreferrer"
            target="_blank"
          >
            <Instagram
              className="block text-primary-500 hover:text-primary-600 duration-300 transition"
              fontSize="large"
            />
          </a>
          <a href="https://www.facebook.com/kadaalimited" rel="noreferrer" target="_blank">
            <Facebook
              className="block text-primary-500 hover:text-primary-600 duration-300 transition"
              fontSize="large"
            />
          </a>
        </div>
        {/* bottom */}
        <div className="mt-14">
          <div className="flex items-center md:justify-between flex-col justify-center md:flex-row">
            <div className="w-full xl:w-[60%]">
              <div className="flex items-center gap-10 justify-center md:justify-start">
                <Link className="text-gray-400" to="/privacy-policy">
                  Privacy Policy
                </Link>
                <Link className="text-gray-400" to="/terms-of-use">
                  Terms of Use
                </Link>
              </div>
            </div>
            <div className="w-full md:w-[30%] flex justify-center md:justify-end items-center mt-6 md:mt-0">
              <div>
                <p className="text-gray-600 text-center text-sm md:text-base">
                  Copyright © {year} KadaaLimited. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
