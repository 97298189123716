import { TickCircle } from "@assets/images";
import { Button } from "@lib/Button";
import { useNavigate } from "react-router-dom";

export const AccountVerifiedScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[100vh] 2xl:h-[95vh]">
      <div
        className={`bg-hero_banner_3 bg-fixed w-full h-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <div className="bg-white w-full md:w-1/2 mx-auto rounded-xl overflow-hidden p-8">
            <div className="flex items-center gap-5 justify-center text-gray-400 mb-8">
              <img src={TickCircle} alt="success" className=" w-6 h-6 md:w-4 md:h-4" />
              <p>Thanks for verifying your email address.</p>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-3xl font-bold text-center">
                You're almost ready to manage your Kadaa deliveries.
              </h1>
              <p className="text-base text-gray-400 text-center my-5">
                We need to ask you for a few details to finish setting up your
                account.
              </p>
            </div>

            <Button
              label="Get Started"
              onClick={() => navigate("/account-setup")}
              rounded
            />
          </div>
        </div>
      </div>
    </div>
  );
};
