import { useAppSelector } from "@hooks/useAppSelector";
import { HomeLayout } from "@layout";
import {
  AboutPage,
  AccountVerifiedScreen,
  ForgotPasswordScreen,
  GetQuoteScreen,
  HomePage,
  LoginScreen,
  OurGoalsPage,
  PaymentCompletedScreen,
  PrivacyPolicyPage,
  RegisterScreen,
  ResetPasswordScreen,
  SetupAccountScreen,
  ShipPacelScreen,
  TermsOfUse,
  TrackShipmentScreen,
  UserInvoices,
  UserSettings,
  UserShipmentHistory,
  VerifyAccountEmailSentScreen,
  VerifyingAccountScreen,
} from "@pages";
import { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const AppRouter = () => {
  const { access_token } = useAppSelector((state) => state.user);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route path="/" index element={<HomePage />} />
          <Route path="get-quotes" element={<GetQuoteScreen />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="our-goals" element={<OurGoalsPage />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="account-setup" element={<SetupAccountScreen />} />
          <Route path="track-parcel" element={<TrackShipmentScreen />} />
          <Route path="payment-complete" element={<PaymentCompletedScreen />} />
          <Route
            path="/"
            element={
              access_token ? (
                <Outlet />
              ) : (
                <Navigate to={`/auth/login?redirect=/ship-parcel`} />
              )
            }
          >
            <Route path="ship-parcel" element={<ShipPacelScreen />} />
          </Route>
          <Route
            path="/"
            element={
              access_token ? <Outlet /> : <Navigate to={`/auth/login`} />
            }
          >
            <Route path="dashboard" element={<UserShipmentHistory />} />
            <Route path="dashboard/invoices" element={<UserInvoices />} />
            <Route path="settings" element={<UserSettings />} />
          </Route>
        </Route>
        <Route path="/auth" element={<HomeLayout />}>
          <Route path="login" index element={<LoginScreen />} />
          <Route path="register" index element={<RegisterScreen />} />
          <Route
            path="forgot-password"
            index
            element={<ForgotPasswordScreen />}
          />
          <Route
            path="reset-password"
            index
            element={<ResetPasswordScreen />}
          />
          <Route
            path="verify-account"
            element={<VerifyAccountEmailSentScreen />}
          />
          <Route
            path="verifying-account"
            index
            element={<VerifyingAccountScreen />}
          />
          <Route path="account-verified" element={<AccountVerifiedScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
