import { useAppDispatch } from "@hooks/useAppDispatch";
import { httpClient } from "@network/api";
import {
  BaseError,
  Endpoints,
  IForgotPassword,
  IForgotPasswordResponse,
  ILoginAccount,
  ILoginAccountResponse,
  IRegisterWithEmail,
  IRegisterWithEmailResponse,
  IResetPassword,
  IResetPasswordResponse,
  ISetupAccount,
  ISetupAccountResponse,
  IVerifyAccountOtp,
  IVerifyAccountOtpResponse,
} from "@network/interface";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { showpageLoader, updateUser, updateUserEmail } from "@app/index";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAppSelector } from "@hooks/useAppSelector";

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectTo = searchParams.get("redirect");

  const { mutate, isLoading } = useMutation(
    async (values: ILoginAccount) => {
      const res = await httpClient.post<ILoginAccountResponse>(
        Endpoints.LOGIN,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success("welcome back", { id: "success" });
        dispatch(updateUser(data));
        navigate(redirectTo ? redirectTo : "/dashboard");
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: "error" });
      },
    }
  );

  const onSubmit = (values: ILoginAccount) => {
    mutate(values);
  };

  const initialValues: ILoginAccount = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email address!.")
      .required("Please provide your email address!."),
    password: yup.string().required("Please provide a your account password!."),
  });

  const { dirty, errors, values, handleChange, handleSubmit, isValid } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  const isDisabled = !dirty || !isValid;

  return { isLoading, values, errors, handleChange, handleSubmit, isDisabled };
};

export const useForgotPassword = () => {
  const { mutate, isLoading } = useMutation(
    async (values: IForgotPassword) => {
      const res = await httpClient.post<IForgotPasswordResponse>(
        Endpoints.FORGOT_PASSWORD,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message, { id: "success" });
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: "error" });
      },
    }
  );

  const onSubmit = (values: IForgotPassword) => {
    mutate(values);
  };

  const initialValues: IForgotPassword = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email address!.")
      .required("Please provide your email address!."),
  });

  const { dirty, errors, values, handleChange, handleSubmit, isValid } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  const isDisabled = !dirty || !isValid;

  return { isLoading, values, errors, handleChange, handleSubmit, isDisabled };
};

export const useResendOtp = () => {
  const user = useAppSelector((state) => state.user);
  const { mutate, isLoading } = useMutation(
    async () => {
      const res = await httpClient.post<IForgotPasswordResponse>(
        `${Endpoints.RESEND_ACCOUNT_OTP}`,
        { email: user?.user?.email }
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message, { id: "success" });
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: "error" });
      },
    }
  );

  const onSubmit = () => {
    mutate();
  };

  return { isLoading, resendOtp: onSubmit };
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const { mutate, isLoading } = useMutation(
    async (values: IResetPassword) => {
      const res = await httpClient.post<IResetPasswordResponse>(
        Endpoints.RESET_PASSWORD,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message, { id: "success" });
        navigate("/auth/login");
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: "error" });
      },
    }
  );

  const onSubmit = (values: IResetPassword) => {
    mutate(values);
  };

  const initialValues: IResetPassword = {
    confirmNewPassword: "",
    newPassword: "",
    email,
    token,
  };

  const validationSchema = yup.object().shape({
    newPassword: yup.string().required("Please provide a password!."),
    confirmNewPassword: yup
      .string()
      .required("Ensure that both password matches!."),
  });

  const { dirty, errors, values, handleChange, handleSubmit, isValid } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  const isDisabled = !dirty || !isValid;

  return { isLoading, values, errors, handleChange, handleSubmit, isDisabled };
};

export const useRegisterWithEmail = () => {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email address!.")
      .required("Please provide your email address!."),
    password: yup.string().required("Please provide a your account password!."),
    fullName: yup.string().required("Please provide a your full name!."),
    phoneNumber: yup
      .string()
      .matches(
        // Regular expression pattern for a phone number (adjust as needed)
        /^\+?[1-9]\d{1,11}$/,
        // Error message if the phone number does not match the pattern
        "Invalid phone number"
      )
      .min(8, "Phone number is too short")
      .max(12, "Phone number is too long")
      .required("Please provide a valid phone number!."),
    accept_terms_and_conditions: yup
      .boolean()
      .required("Please agree to terms and conditions!.")
      .oneOf([true], "Please accept our terms and conditions!."),
  });

  const initialValues: IRegisterWithEmail = {
    email: "",
    password: "",
    fullName: "",
    phoneNumber: "",
    accept_terms_and_conditions: false,
    receive_offer_letter: false,
    receive_news_letter: false,
  };

  const dispatch = useAppDispatch();

  const { mutate, isLoading } = useMutation(
    async (values: IRegisterWithEmail) => {
      const res = await httpClient.post<IRegisterWithEmailResponse>(
        Endpoints.REGISTER_WITH_EMAIL,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success("Success", { id: "success" });
        dispatch(updateUserEmail({ email: data?.email }));
        navigate("/auth/verify-account");
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        toast.error(message, { id: "error" });
      },
    }
  );

  const onSubmit = (values: IRegisterWithEmail) => {
    if (values.accept_terms_and_conditions === false) {
      toast.error("Please accept terms and conditions", { id: "error" });
      setFieldError(
        "accept_terms_and_conditions",
        "Please accept terms and conditions!."
      );
      return;
    } else {
      mutate({ ...values, phoneNumber: values.phoneNumber.toString() });
    }
  };

  const {
    dirty,
    errors,
    values,
    handleChange,
    handleSubmit,
    isValid,
    setFieldError,
    touched,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const isDisabled = !dirty || !isValid;

  return {
    isLoading,
    values,
    errors,
    handleChange,
    handleSubmit,
    isDisabled,
    touched,
  };
};

export enum Status {
  PENDING,
  SUCCESS,
  ERROR,
}

export const useVerifyAccount = () => {
  let [searchParams] = useSearchParams();
  const [error_message, seterror_message] = useState("");

  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const [status, setStatus] = useState<Status | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { mutate, isLoading } = useMutation(
    async (values: IVerifyAccountOtp) => {
      const res = await httpClient.post<IVerifyAccountOtpResponse>(
        Endpoints.VERIFY_ACCOUNT_OTP,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success("Success", { id: "success" });
        setTimeout(() => dispatch(showpageLoader(true)), 1500);
        setTimeout(() => {
          dispatch(showpageLoader(false));
          setStatus(null);
          navigate("/auth/account-verified");
        }, 4000);
      },
      onError: (error: BaseError) => {
        setStatus(Status.ERROR);
        const message = error?.response?.data?.message;
        seterror_message(message);
        toast.error(message, { id: "error" });
      },
    }
  );

  const onSubmit = (values: IVerifyAccountOtp) => {
    mutate(values);
  };

  useEffect(() => {
    if (token && email) {
      onSubmit({ token, email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, status, error_message };
};

export const useSetupProfile = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);

  const { mutate, isLoading } = useMutation(
    async (values: ISetupAccount) => {
      const res = await httpClient.post<ISetupAccountResponse>(
        Endpoints.SETUP_ACCOUNT,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message, { id: "success" });
        navigate("/auth/login");
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        toast.error(message, { id: "error" });
      },
    }
  );

  const onSubmit = (values: ISetupAccount) => {
    mutate(values);
  };

  const initialValues: ISetupAccount = {
    address: "",
    postal_code: "",
    phone: "",
    email: user?.email,
  };

  const validationSchema = yup.object().shape({
    address: yup.string().required("This field is required!."),
    postal_code: yup.string().required("This field is required!."),
    phone: yup.string().required("This field is required!."),
  });

  const { handleChange, values, errors, isValid, dirty, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  const isDisabled = !dirty && !isValid;

  return {
    isLoading,
    handleChange,
    values,
    errors,
    isValid,
    dirty,
    handleSubmit,
    isDisabled,
  };
};
