import Pagination from "@components/Pagination";
import { Spinner } from "@lib/Loader";
import { useGetUserInvoices } from "@network/hooks/shipment";
import moment from "moment";

export const UserInvoices = () => {
  const { invoices, isLoading, page, setPage, totalPages } =
    useGetUserInvoices();

  return (
    <div className="bg-gray-100 py-10 min-h-[70vh]">
      <div className="w-[90%] 2xl:w-[60%] mx-auto">
        <div className="flex justify-between items-center mb-5 xl:flex-row flex-col gap-5 md:gap-0">
          <h1 className="font-bold text-2xl capitalize ">All Invoices</h1>
        </div>

        <div className="bg-white rounded-lg p-4">
          <div className="flex items-center justify-between border-b border-b-gray-300 pb-5">
            <p>Your Invoices</p>
          </div>
          <div className="overflow-x-scroll">
            {isLoading ? (
              <div className="w-full h-[10vh] flex items-center justify-center">
                <Spinner />
              </div>
            ) : invoices?.length <= 0 && !isLoading ? (
              <div className="w-full py-6 flex items-center justify-center">
                <h1 className="text-lg text-red-500 capitalize ">
                  No Invoices to view
                </h1>
              </div>
            ) : (
              <>
                <table className="w-full divide-y divide-gray-200 overflow-x-scroll">
                  <thead>
                    <tr>
                      {/* <th className="py-3 px-3 text-left">Checkbox</th> */}
                      <th className="py-3 px-3 text-center text-xs">
                        Invoice ID
                      </th>
                      <th className="py-3 px-3 text-center text-xs">Shipper</th>
                      <th className="py-3 px-3 text-center text-xs">
                        Receiver
                      </th>
                      <th className="py-3 px-3 text-center text-xs">
                        Invoice Date
                      </th>
                      <th className="py-3 px-3 text-center text-xs">Pickup</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr>
                        {/* <td className="py-3 px-3 text-left">Checkbox</td> */}
                        <td className="py-3 px-3 text-left text-sm">
                          {invoice._invoice_number}
                        </td>
                        <td className="py-3 px-3 text-left text-sm">
                          {invoice.shipper}
                        </td>
                        <td className="py-3 px-3 text-left text-sm">
                          {invoice.receiver}
                        </td>
                        <td className="py-3 px-3 text-center text-sm">
                          {moment(invoice.createdAt).format(
                            "Do-MMM-YYYY | hh-mm a"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {invoices.length > 0 ? (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    setPage={setPage}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
