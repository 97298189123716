import { Button } from "@lib/Button";
import { Spinner } from "@lib/Loader";
import { useGetUserShipments } from "@network/hooks/shipment";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { PaymentModal } from "@components/Modal/PaymentModal";
import useDisclosure from "@hooks/useDisclosure";
import { useState } from "react";
import Pagination from "@components/Pagination";

export const UserShipmentHistory = () => {
  const navigate = useNavigate();
  const { shipments, isLoading, page, setPage, totalPages } =
    useGetUserShipments();

  const checkShipmentHasPendingPayment = () => {
    return shipments?.some((ship) => ship.status === "PENDING_PAYMENT");
  };

  const { onOpen, isOpen, onClose } = useDisclosure();

  const [selectedOrderAmount, setSelectedOrderAmount] = useState<number | null>(
    null
  );
  const [selectedParcelId, setSelectedParcelId] = useState<string | undefined>(
    undefined
  );

  return (
    <div className="bg-gray-100 py-10 min-h-[70vh]">
      <div className="w-[90%] 2xl:w-[60%] mx-auto">
        <div className="flex justify-between items-center mb-5 xl:flex-row flex-col gap-5 md:gap-0">
          <h1 className="font-bold text-2xl capitalize ">
            All Shipment History
          </h1>
          <div className="w-full flex-col xl:flex-row xl:w-[30%] flex items-center justify-between gap-4">
            <Button
              label="Send Parcel"
              className="w-[45%] py-4"
              onClick={() => navigate("/ship-parcel")}
            />
            <Button
              label="Track"
              className="w-[45%] py-4"
              onClick={() => navigate("/track-parcel")}
            />
          </div>
        </div>

        <div className="bg-white rounded-lg p-4">
          <div className="flex items-center justify-between border-b border-b-gray-300 pb-5">
            <p>Customer Orders</p>
            {/* <div>
              <div className="border border-gray-400 rounded-md flex itesm-center gap-3">
                <SearchIcon /> 
                <input placeholder="search" className="w-full" />
              </div> 
            </div>*/}
          </div>
          <div className="overflow-x-scroll">
            {isLoading ? (
              <div className="w-full h-[10vh] flex items-center justify-center">
                <Spinner />
              </div>
            ) : shipments?.length <= 0 && !isLoading ? (
              <div className="w-full py-6 flex items-center justify-center">
                <h1 className="text-lg text-red-500 capitalize ">
                  No shipment history to view
                </h1>
              </div>
            ) : (
              <>
                <table className="w-full divide-y divide-gray-200 overflow-x-scroll">
                  <thead>
                    <tr>
                      {/* <th className="py-3 px-3 text-left">Checkbox</th> */}
                      <th className="py-3 px-3 text-center text-xs">
                        Tracking ID
                      </th>
                      <th className="py-3 px-3 text-center text-xs">
                        Order Date
                      </th>
                      <th className="py-3 px-3 text-center text-xs">Pickup</th>
                      <th className="py-3 px-3 text-center text-xs">
                        Delivery
                      </th>
                      <th className="py-3 px-3 text-center text-xs">
                        Price (£)
                      </th>
                      <th className="py-3 px-3 text-center text-xs">
                        Weight (KG)
                      </th>
                      <th className="py-3 px-3 text-center text-xs">
                        Mode of Transportation
                      </th>
                      <th className="py-3 px-3 text-center text-xs">Status</th>
                      {checkShipmentHasPendingPayment() ? (
                        <th className="py-3 px-3 text-center text-xs">
                          Action
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {shipments.map((shipment) => (
                      <tr>
                        {/* <td className="py-3 px-3 text-left">Checkbox</td> */}
                        <td className="py-3 px-3 text-left text-sm">
                          {shipment._tracking_id}
                        </td>
                        <td className="py-3 px-3 text-center text-sm">
                          {moment(shipment.createdAt).format(
                            "Do-MMM-YYYY | hh-mm a"
                          )}
                        </td>
                        <td className="py-3 px-3 text-center text-sm">{`${shipment.shipper_street_name}`}</td>
                        <td className="py-3 px-3 text-center text-sm">{`${shipment.receiver_street_name}`}</td>
                        <td className="py-3 px-3 text-center text-sm">{`£ ${shipment.total}`}</td>
                        <td className="py-3 px-3 text-center text-sm">{`${shipment.weight} KG`}</td>
                        <td className="py-3 px-3 text-center text-sm">{`${
                          shipment?.mode_of_transportation
                            ? shipment?.mode_of_transportation
                            : "--/--"
                        }`}</td>
                        <td className="py-3 px-3 text-center text-sm">{`${shipment?.status
                          ?.split("_")
                          .join(" ")}`}</td>
                        {shipment?.status === "PENDING_PAYMENT" ? (
                          <td className="py-3 px-3 text-center text-sm">
                            <Button
                              label="Make Payment"
                              className="py-1 px-3"
                              onClick={() => {
                                setSelectedOrderAmount(
                                  Number(shipment?.total) ?? 0
                                );
                                setSelectedParcelId(shipment?._shipment_id);
                                onOpen();
                              }}
                            />
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {shipments.length > 0 ? (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    setPage={setPage}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      <PaymentModal
        isOpen={isOpen}
        onClose={onClose}
        amount={selectedOrderAmount}
        parcel_id={selectedParcelId}
      />
    </div>
  );
};
