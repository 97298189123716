import { useAppSelector } from "@hooks/useAppSelector";
import { Button } from "@lib/Button";
import { Input } from "@lib/Input";
import { useChangePassword, useUpdateUser } from "@network/hooks/profile";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const UserSettings = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("profile");

  const user = useAppSelector((state) => state.user?.user);

  const userUpdateHandler = useUpdateUser();
  const passwordChangeHandler = useChangePassword();

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    const capitalizedValue = value.toUpperCase();
    userUpdateHandler.handleChange({
      target: { name, value: capitalizedValue },
    });
  };

  return (
    <div className="bg-gray-100 py-10 min-h-[70vh]">
      <div className="w-[90%] 2xl:w-[60%] mx-auto">
        <div className="flex justify-between items-center mb-5 xl:flex-row flex-col gap-5 md:gap-0">
          <h1 className="font-bold text-2xl capitalize ">Setting</h1>
        </div>

        <div className="bg-white rounded-lg p-4">
          <div className="flex gap-5 border-b border-b-gray-300 pb-5">
            <div
              onClick={() => setTab("profile")}
              className={twMerge(
                "rounded-md text-black bg-gray-300 opacity-30 px-4 py-2 cursor-pointer",
                tab === "profile" ? "bg-primary-500 text-white opacity-100" : ""
              )}
            >
              <p>Profile Settings</p>
            </div>
            <div
              onClick={() => setTab("change-password")}
              className={twMerge(
                "rounded-md text-black bg-gray-300 opacity-30 px-4 py-2 cursor-pointer",
                tab === "change-password"
                  ? "bg-primary-500 text-white opacity-100"
                  : ""
              )}
            >
              <p>Change Password</p>
            </div>
          </div>
          {tab === "profile" ? (
            <form
              onSubmit={userUpdateHandler.handleSubmit}
              className="w-full lg:w-[50%] py-4"
            >
              <Input
                label="Email Address"
                placeholder="shipper@gmail.com"
                disabled
                required
                type="email"
                name="emailAddress"
                // onChange={userUpdateHandler.handleChange}
                value={user?.email}
                // error={userUpdateHandler.errors?.}
              />
              <Input
                label="Full Name"
                placeholder="Full Name"
                required
                disabled
                name="fullname"
                //   onChange={handleChange}
                value={user?.fullName}
                //   error={errors?.shipper_fullname}
              />
              <Input
                label="Phone Number"
                placeholder="Phone Number"
                required
                name="phone"
                onChange={userUpdateHandler.handleChange}
                value={userUpdateHandler.values.phone}
                error={userUpdateHandler.errors?.phone}
              />
              <Input
                label="Address"
                placeholder="Address"
                required
                name="address"
                onChange={userUpdateHandler.handleChange}
                value={userUpdateHandler.values.address}
                error={userUpdateHandler.errors?.address}
              />
              <Input
                label="Postal Code"
                placeholder="Enter Postal code"
                required
                name="postal_code"
                onChange={handleInputChange}
                value={userUpdateHandler.values.postal_code}
                error={userUpdateHandler.errors?.postal_code}
              />
              <Button
                label="Save"
                disabled={
                  userUpdateHandler?.isDisabled || userUpdateHandler?.isLoading
                }
                isLoading={userUpdateHandler.isLoading}
              />
            </form>
          ) : tab === "change-password" ? (
            <form
              onSubmit={passwordChangeHandler.handleSubmit}
              className="w-full lg:w-[50%] py-4"
            >
              <Input
                label="Old Password"
                placeholder="Old Password"
                required
                name="oldPassword"
                type="password"
                onChange={passwordChangeHandler.handleChange}
                value={passwordChangeHandler?.values.oldPassword}
                error={passwordChangeHandler?.errors?.oldPassword}
              />
              <Input
                label="New Password"
                placeholder="New Password"
                required
                type="password"
                name="newPassword"
                onChange={passwordChangeHandler.handleChange}
                value={passwordChangeHandler?.values.newPassword}
                error={passwordChangeHandler?.errors?.newPassword}
              />
              <Input
                label="Confirm New Password"
                placeholder="Confirm New Password"
                required
                type="password"
                name="confirmNewPassword"
                onChange={passwordChangeHandler.handleChange}
                value={passwordChangeHandler?.values.confirmNewPassword}
                error={passwordChangeHandler?.errors?.confirmNewPassword}
              />
              <Button
                label="Save"
                disabled={
                  passwordChangeHandler?.isDisabled ||
                  passwordChangeHandler?.isLoading
                }
                isLoading={passwordChangeHandler.isLoading}
              />
            </form>
          ) : null}
        </div>
      </div>
    </div>
  );
};
