import { persistor, store } from "@app/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { AppRouter } from "./router";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  useEffect(() => {
    Crisp.configure("92912210-c458-47e2-a21f-07d9fcf579a4");
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 2000,
              style: { zIndex: "1000000000px" },
            }}
          />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
