import Logo from "@assets/images/logo.png";
import { useEffect } from "react";

interface IProps {
  className?: string;
}

export const Spinner = ({ className }: IProps) => {
  return (
    <div
      className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${className}`}
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
};

export const PageLoader = ({ show }: { show: boolean }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add(`overflow-hidden`);
    } else {
      document.body.classList.remove(`overflow-hidden`);
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [show]);
  return show ? (
    <div className="w-[100vw] h-[100vh] fixed overscroll-none z-[1000000] bg-[rgba(0,0,0,0.7)] flex items-center justify-center">
      <div className="w-32 h-32 rounded-ld animate-wiggle bg-white flex flex-col items-center justify-center rounded-lg">
        <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin-slow dark:border-violet-400" />
        <p className="mt-4">Loading</p>
      </div>
    </div>
  ) : null;
};

interface IProps {
  pad?: string;
  width?: string;
}

export const Loader = ({ pad }: IProps) => {
  return (
    <div
      style={{
        padding: pad,
      }}
      className="p-4 h-full w-full flex align-center justify-center"
    >
      <div className="h-[60px] w-[60px]">
        <img className="h-full w-full rotating" src={Logo} alt="kadaa Logo" />
      </div>
    </div>
  );
};
