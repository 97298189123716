import { useAppSelector } from "@hooks/useAppSelector";
import { useResendOtp } from "@network/hooks/auth";

export const VerifyAccountEmailSentScreen = () => {
  const { user } = useAppSelector((state) => state.user);

  const { resendOtp } = useResendOtp();

  return (
    <div className="w-full h-[100vh] 2xl:h-[95vh]">
      <div
        className={`bg-hero_banner_3 bg-fixed w-full h-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto">
          <div className="bg-white w-full md:w-1/2 rounded-xl overflow-hidden p-8">
            <h1 className="text-3xl font-bold">Verify your email address</h1>
            <p className="text-sm text-gray-400">
              A verification email has been sent to:
            </p>
            <p className="text-lg mb-5 text-primary-500">{user?.email}</p>
            <p className="text-sm text-gray-400 mb-4">
              Simply click on the link in the email to finish setting up your
              account.
            </p>
            <p className="text-sm text-gray-400 mb-2">
              The link in the email will expire after 30 minutes.
            </p>
            <p className="text-gray-400 underline underline-offset-2 mb-2">
              Did not received any email?
            </p>
            <p className="text-gray-400 text-base mb-2">
              Please check your junk folder
            </p>
            <p
              className="text-primary-500 underline underline-offset-2 cursor-pointer"
              onClick={resendOtp}
            >
              Resend verification email
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
