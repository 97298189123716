import { ChevronDownArrow } from "@assets/icons";
import { Spinner } from "@lib/Loader";
import { useTrackShipment } from "@network/hooks/shipment";
import moment from "moment";
import React, { useState } from "react";

export const TrackShipmentScreen = () => {
  const [showDetails, setShowDetails] = useState(false);
  const { isLoading, shipmentDetails, handleChange, handleTrack, value } =
    useTrackShipment();

  return (
    <div className="w-[90%] 2xl:w-[60%] mx-auto mt-32 mb-10">
      <div className="px-4 py-2 border-b border-b-dark-200">
        <div>
          {/* <ArrowLeftIcon /> */}
          <p>Package History</p>
        </div>
      </div>
      <div className="my-6 flex flex-col items-center justify-center w-full">
        <h1 className="text-2xl font-bold">Kadaa Shipment Tracking</h1>
        <div className="rounded-md border border-dark-200 mt-5 lg:w-[35%] w-full flex h-[4rem] justify-between p-1">
          <input
            placeholder="Enter your tracking number(s)"
            className="p-2 w-full h-full ring-0 focus:ring-0 border-none focus:outline-none"
            onChange={handleChange}
            value={value}
          />
          <button
            onClick={handleTrack}
            className="cursor-pointer flex items-center justify-center bg-primary-500 px-8 text-white rounded-sm h-full"
          >
            Track
          </button>
        </div>
      </div>
      {/* tracking details */}
      {isLoading ? (
        <div className="w-full h-[10vh] flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        shipmentDetails && (
          <div className="w-full border border-dark-300 rounded-md mt-4">
            <>
              <div className="p-8 border-b border-b-dark-300">
                {/* tracking details header */}
                <div className="flex align-center gap-2 font-medium">
                  Tracking Code:{" "}
                  <span className="flex aling-center gap-4 text-gray-400 font-normal">
                    {shipmentDetails?._tracking_id}{" "}
                    {/* <p className="text-primary-500 text-normal">icon</p> */}
                  </span>
                </div>
              </div>
              <div className="w-full p-8">
                <h1 className="text-3xl text-primary-500 font-medium uppercase">
                  {shipmentDetails?.status.split("_").join(" ")}
                </h1>
                {/* June, 01 2023 16:15 */}
                <p className="my-8 text-gray-400 text-base">
                  <span className="font-bold text-black">Local time: </span>{moment(shipmentDetails?.createdAt).format(
                    "dddd, MMM Do YYYY"
                  )}{" "}
                  , <span className="font-bold text-black">Destination Area:</span>{" "}
                  {shipmentDetails?.receiver_street_name} -
                  {shipmentDetails?.receiver_country}
                </p>
                <h1 className="text-lg font-normal mb-6">
                  <span className="text-xl font-bold uppercase">
                    Pickup Area:
                  </span>{" "}
                  {shipmentDetails?.shipper_street_name} -{" "}
                  {shipmentDetails?.shipper_country}
                </h1>
                <div className="w-full h-1 bg-primary-500 relative">
                  <div className="absolute right-0 bottom-[50%] translate-y-[50%] w-5 h-5 rounded-full bg-primary-500" />
                </div>
                <h1 className="text-lg font-normal mt-4 text-right">
                  <span className="text-xl font-bold uppercase">
                    Delivery Area:
                  </span>{" "}
                  {shipmentDetails?.receiver_street_name} -{" "}
                  {shipmentDetails?.receiver_country}
                </h1>
              </div>
              <div className="border-t border-t-dark-300 p-8">
                <div className="py-4 flex items-center justify-between mb-5">
                  <h3 className="text-xl font-medium">Shipment Details</h3>
                  <ChevronDownArrow
                    onClick={() => setShowDetails((prev) => !prev)}
                    className={`cursor-pointer rotate-180 transition duration-500 ${
                      showDetails ? "rotate-0" : ""
                    }`}
                  />
                </div>
                {showDetails ? (
                  <>
                    <div className="w-full rounded-md bg-gray-300 p-4 flex items-center">
                      <p className="w-[35%]">Delivery Date / Time</p>
                      <p className="w-[35%]">
                        {moment(shipmentDetails?.createdAt).format("dddd MMMM, YYYY - hh:mm A")} Local time
                      </p>
                    </div>

                    <div className="flex items-center py-3">
                      <p className="w-[35%] pr-4">
                        To protect your privacy, more delivery details are
                        available after validation
                      </p>
                      <p className="w-[35%]">
                        Status:
                        <span className="ml-3 px-3 py-2 text-sm rounded-md bg-primary-100 text-primary-500">
                          {shipmentDetails?.status?.split("_").join(" ")}
                        </span>
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          </div>
        )
      )}
    </div>
  );
};
