import React from "react";

export const AboutPage = () => {
  return (
    <div>
      <div className="w-full bg-header_overflow bg-no-repeat bg-center bg-cover h-[15vh] flex items-center">
        <div className="w-[90%] 2xl:w-[60%] mx-auto flex items-center justify-end">
          <h1 className="text-2xl text-white">About Us</h1>
        </div>
      </div>
      <div className="w-[90%] 2xl:w-[60%] mx-auto px-4 py-8">
        <p>
          At Kadaa Limited, we are more than just a logistics company - we are
          trailblazers in the pursuit of a greener future. As a sustainable
          logistics provider, we take immense pride in our commitment to
          environmental stewardship and innovation. At the core of our
          operations lies a driving force - the exclusive use of electric
          vehicles (EVs) to deliver your shipments efficiently and responsibly.
        </p>
        <h2 className="text-2xl font-bold my-4">Our Sustainable Mission</h2>
        <p className="mb-4">
          Kadaa Limited was founded with a mission to lead the logistics
          industry towards a more sustainable and eco-conscious direction. By
          harnessing the potential of electric vehicles, we aim to reduce our
          carbon footprint and promote a cleaner, greener tomorrow. Our passion
          for sustainability drives every aspect of our logistics solutions,
          from local deliveries to long-haul transportation.
        </p>
        <h2 className="text-2xl font-bold my-4">Embracing Electric Mobility</h2>
        <p className="mb-4">
          At Kadaa Limited, we've embraced the power of electric mobility to
          revolutionize logistics. Our fleet of cutting-edge electric vehicles
          is at the forefront of eco-friendly transportation, contributing to
          reduced emissions and air pollution. By adopting EVs, we proactively
          address the global challenge of climate change and align our services
          with the green vision of a sustainable planet.
        </p>
        <h2 className="text-2xl font-bold my-4">Why Choose Us</h2>
        <ol className="list-decimal">
          <li className="mb-2">
            <b>Sustainability Champion:</b> We are unyielding in our dedication
            to sustainability, striving to be a leading force in green logistics
            solutions that safeguard the environment and inspire others to
            follow suit.
          </li>
          <li className="mb-2">
            <b>Innovative Technologies:</b> With advanced logistics
            technologies, we optimize EV routes, ensuring timely deliveries
            while minimizing energy consumption and waste.
          </li>
          <li className="mb-2">
            <b>Customer-Centered Approach:</b> Our clients' needs are at the
            heart of everything we do. We deliver personalized, efficient, and
            reliable logistics solutions that align with your eco-conscious
            values.
          </li>
          <li className="mb-2">
            <b>Nationwide Reach:</b> Our extensive network of electric vehicles
            spans across the nation, offering comprehensive coverage for your
            sustainable logistics requirements.
          </li>
          <li className="mb-2">
            <b>Eco-Partnerships:</b> We collaborate with like-minded businesses
            and organizations that share our commitment to sustainability,
            fostering a collective effort towards a greener future.
          </li>
        </ol>
        <h2 className="text-2xl font-bold my-4">Our Sustainable Services</h2>
        <ul className="list-disc">
          <li className="mb-2">
            <b>Electric Vehicle Deliveries:</b> From small packages to bulk
            shipments, our EVs handle all your deliveries with precision and
            eco-responsibility.
          </li>
          <li className="mb-2">
            <b>Green Warehousing:</b> Our sustainable warehousing solutions
            integrate energy-efficient practices to store your inventory safely
            and sustainably.
          </li>
          <li className="mb-2">
            <b>Carbon-Neutral Freight Forwarding:</b> Our freight forwarding
            services utilize carbon offset initiatives, ensuring carbon-neutral
            transport for your goods.
          </li>
          <li className="mb-2">
            <b>Eco-Friendly Last-Mile Delivery:</b> Our last-mile EV delivery
            service ensures that your packages reach their destination swiftly
            and sustainably.
          </li>
        </ul>
        <h2 className="text-2xl font-bold my-4">
          Together Towards a Greener Tomorrow
        </h2>
        <p>
          Join us on this transformative journey towards a sustainable logistics
          future. At Kadaa Limited, we believe that sustainable logistics is not
          just an option; it is our collective responsibility to safeguard our
          planet for future generations.
        </p>
        <p className="mt-2">
          Contact us today and experience the power of sustainable logistics
          with our electric vehicle fleet.
        </p>
      </div>
    </div>
  );
};
