import * as React from "react";

export const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={73}
    height={72}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={73}
        height={72}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M72.797 0h-72v72h72V0Z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#70B649"
          d="M72.797 36.004c0 19.882-16.122 36.003-36.004 36.003C16.911 72.007.797 55.886.797 36.004S16.919 0 36.8 0c19.882 0 35.996 16.122 35.996 36.004Z"
        />
        <path
          fill="#fff"
          d="m50.756 40.504 5.656 2.861c.49.31.785.846.785 1.42v12.218c0 .928-.755 1.684-1.684 1.684-.317 0-.627-.091-.891-.257L42.125 51.27a1.684 1.684 0 0 1-.529-2.318c.046-.068.098-.136.151-.204l6.834-7.92a1.7 1.7 0 0 1 2.175-.325ZM53.46 27.615h-9.553a7.925 7.925 0 0 0-5.8 2.522l-11.31 12.127a1.598 1.598 0 0 1-2.432-.098l-.03-.038a1.603 1.603 0 0 1 .158-2.107l3.836-3.783a4.412 4.412 0 0 0 1.314-3.134v-9.65c0-1.246-.62-2.409-1.661-3.104l-10.95-7.309a.405.405 0 0 0-.634.34v32.523l.008.68a12.52 12.52 0 0 0 1.412 5.308c.015.022.023.045.038.068.045.083.09.166.136.241.022.038.037.068.06.106.06.106.121.211.181.31l.038.06c.053.09.106.174.166.257.023.03.046.068.068.098.06.09.121.174.181.264l.038.053c.068.098.144.196.212.295l.068.09a5.234 5.234 0 0 0 .241.302c.076.09.151.189.234.28.023.022.038.045.06.067.069.076.13.151.197.22a.36.36 0 0 0 .075.075c.084.09.167.181.257.264l.046.046c.075.075.15.143.226.219.03.022.053.052.083.075.083.076.166.151.242.227l.03.03c.09.083.189.158.28.241l.09.076c.083.068.166.128.242.196.015.015.03.03.053.038.098.075.196.151.294.219l.068.045c.083.06.166.114.25.174l.082.06c.106.068.204.136.31.204l.053.03c.09.053.18.114.271.167l.091.053c.098.06.204.113.302.166l.03.015c.114.06.22.113.333.173.037.015.075.038.113.053.09.046.181.09.28.129l.052.022c.113.053.227.098.348.151l.083.03c.098.038.188.076.287.114l.09.037c.12.046.234.084.355.129l.06.023c.106.03.204.067.31.098l.106.03c.12.038.241.068.362.098h.015l.378.09c.045.008.098.023.143.03.099.023.197.039.295.061l.06.008c.129.023.257.045.385.06l.091.015c.106.016.211.03.317.038l.098.015c.129.015.265.03.393.038h.053c.098.008.196.015.294.015.053 0 .114.008.166.008.25.007.499-.091.665-.28l25.394-28.15a1.588 1.588 0 0 0-.113-2.243 1.62 1.62 0 0 0-1.065-.4Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.797 0h72v72h-72z" />
      </clipPath>
    </defs>
  </svg>
);

export const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      d="M1.78 1.113a.85.85 0 0 0-.312.069.805.805 0 0 0-.258.177.747.747 0 0 0-.164.257.71.71 0 0 0-.044.297c.107 1.728.614 5.944 3.043 8.574 2.908 3.168 6.695 4.259 10.58 4.098a.848.848 0 0 0 .548-.239.747.747 0 0 0 .227-.525v-2.767a1.108 1.108 0 0 0-.27-.71 1.242 1.242 0 0 0-.68-.402l-1.946-.403a1.307 1.307 0 0 0-.719.06 1.217 1.217 0 0 0-.56.427l-.43.61a.307.307 0 0 1-.167.117.327.327 0 0 1-.208-.01c-.99-.4-4.934-2.128-5.367-5.043a.272.272 0 0 1 .027-.165.296.296 0 0 1 .12-.123l.781-.446c.215-.124.383-.307.482-.524.1-.217.126-.457.075-.689L6.11 1.9a1.146 1.146 0 0 0-.443-.655A1.285 1.285 0 0 0 4.881 1l-3.102.113Z"
    />
  </svg>
);

export const DocumentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={96}
    height={96}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M64 91H32C17.4 91 9 82.6 9 68V28C9 13.4 17.4 5 32 5h32c14.6 0 23 8.4 23 23v40c0 14.6-8.4 23-23 23ZM32 11c-11.44 0-17 5.56-17 17v40c0 11.44 5.56 17 17 17h32c11.44 0 17-5.56 17-17V28c0-11.44-5.56-17-17-17H32Z"
    />
    <path
      fill="currentColor"
      d="M74 37h-8c-6.08 0-11-4.92-11-11v-8c0-1.64 1.36-3 3-3s3 1.36 3 3v8c0 2.76 2.24 5 5 5h8c1.64 0 3 1.36 3 3s-1.36 3-3 3ZM48 55H32c-1.64 0-3-1.36-3-3s1.36-3 3-3h16c1.64 0 3 1.36 3 3s-1.36 3-3 3ZM64 71H32c-1.64 0-3-1.36-3-3s1.36-3 3-3h32c1.64 0 3 1.36 3 3s-1.36 3-3 3Z"
    />
  </svg>
);

export const ParcelIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={96}
    height={96}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M48 53.2c-.52 0-1.04-.12-1.52-.4L11.16 32.36a3.007 3.007 0 0 1-1.08-4.12c.84-1.44 2.64-1.92 4.12-1.08L48 46.72l33.6-19.44c1.44-.84 3.28-.32 4.12 1.08.84 1.44.32 3.28-1.08 4.12L49.56 52.8c-.52.24-1.04.4-1.56.4Z"
    />
    <path
      fill="currentColor"
      d="M48 89.44c-1.64 0-3-1.36-3-3V50.16c0-1.64 1.36-3 3-3s3 1.36 3 3v36.28c0 1.64-1.36 3-3 3Z"
    />
    <path
      fill="currentColor"
      d="M48 91c-3.52 0-7-.76-9.76-2.28L16.88 76.84c-5.8-3.2-10.32-10.92-10.32-17.56v-22.6c0-6.64 4.52-14.32 10.32-17.56L38.24 7.28c5.48-3.04 14.04-3.04 19.52 0l21.36 11.88c5.8 3.2 10.32 10.92 10.32 17.56v22.6c0 6.64-4.52 14.32-10.32 17.56L57.76 88.72C55 90.24 51.52 91 48 91Zm0-80c-2.52 0-5 .52-6.84 1.52L19.8 24.4c-3.84 2.16-7.24 7.88-7.24 12.32v22.6c0 4.4 3.4 10.16 7.24 12.32l21.36 11.88c3.64 2.04 10.04 2.04 13.68 0L76.2 71.64c3.84-2.16 7.24-7.88 7.24-12.32v-22.6c0-4.4-3.4-10.16-7.24-12.32L54.84 12.52C53 11.52 50.52 11 48 11Z"
    />
    <path
      fill="currentColor"
      d="M68 55.96c-1.64 0-3-1.36-3-3V40.08L28.52 19.04c-1.44-.84-1.92-2.68-1.08-4.08a2.96 2.96 0 0 1 4.08-1.08L69.48 35.8A3.01 3.01 0 0 1 71 38.4v14.64c0 1.56-1.36 2.92-3 2.92Z"
    />
  </svg>
);

export const StripeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={55}
    height={73}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M28.979.85C11.639.85.43 10.19.43 24.65c0 14.32 12.867 18.585 21.38 21.408 3.829 1.27 8.587 2.844 8.62 4.401-.024 1.203-2.871 1.39-4.57 1.39-5.657 0-14.336-2.34-21.101-5.689L.43 44.016v23.391l1.834.765c7.04 2.958 15.288 4.662 22.629 4.677 18.498 0 29.538-8.526 29.538-22.8 0-15.576-13.224-19.809-21.972-22.61-3.198-1.027-8.028-2.575-8.028-3.868 0-.687 0-1.722 3.69-1.722 5.55 0 13.266 2.295 18.774 5.58l4.536 2.706V5.49l-1.911-.744C43.052 2.23 35.762.85 28.979.85Zm0 6c5.397 0 11.163.983 16.452 2.79v10.292c-5.658-2.517-12.141-4.083-17.31-4.083-8.745 0-9.69 5.4-9.69 7.722 0 5.673 5.925 7.575 12.198 9.582C41.054 36.49 48.43 39.651 48.43 50.05c0 15.16-16.461 16.8-23.532 16.8-5.94-.012-12.567-1.269-18.468-3.474V53.46c5.979 2.391 13.398 4.39 19.43 4.39 9.163 0 10.56-4.603 10.57-7.372v-.036c-.024-5.868-6.195-7.91-12.732-10.08C13.589 37.014 6.43 33.927 6.43 24.654c0-10.986 8.64-17.805 22.548-17.805Z"
    />
  </svg>
);

export const PaypalIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={96}
    height={96}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M28.52 83h-7.8c-2.08 0-4-.88-5.36-2.48-1.32-1.56-1.92-3.64-1.56-5.64l9.56-57.32C24.56 10.28 30.8 5 38.16 5H56c12.68 0 23 10.32 23 23 0 .84-.04 1.64-.16 2.44-.52 6.24-3.28 12.2-7.8 16.68C66 52.2 59.2 55.04 51.96 55.04H39.44c-.48 0-.92.36-1 .88L35.4 77.04c-.4 2.68-2.28 4.88-4.8 5.64-.8.28-1.56.32-2.08.32Zm9.64-72c-4.44 0-8.16 3.16-8.88 7.52l-9.56 57.32c-.08.4.12.68.24.8s.36.36.76.36h7.8c.68-.2.92-.52 1-.88l3.04-21.08c.48-3.44 3.48-6 6.92-6H52c5.64 0 10.88-2.2 14.84-6.16 3.52-3.48 5.64-8.08 6.08-13 .08-.68.12-1.24.12-1.84 0-9.36-7.64-17-17-17H38.16V11Z"
    />
    <path
      fill="currentColor"
      d="M44.6 91H32.88c-2.08 0-4.04-.92-5.4-2.56-1.36-1.64-1.88-3.76-1.48-5.84l1.04-5.24 1.8-.48c.32-.08.6-.4.68-.8L32.56 55c.48-3.44 3.48-6 6.92-6H52c5.64 0 10.88-2.2 14.84-6.16 3.52-3.48 5.64-8.08 6.08-13l.4-4.68 4.08 2.32c5.96 3.4 9.64 9.72 9.64 16.52 0 6.12-2.4 11.88-6.72 16.28C75.92 64.64 70.16 67 64.04 67h-8.6c-.52 0-.92.36-1 .84l-2.88 17.28c-.6 3.4-3.52 5.88-6.96 5.88Zm-12.32-9.12-.4 1.92c-.08.4.12.72.24.84.12.16.36.36.76.36H44.6c.52 0 .92-.36 1-.84l2.88-17.28c.56-3.4 3.48-5.88 6.92-5.88H64c4.52 0 8.8-1.76 12.04-4.96C79.24 52.84 81 48.52 81 44c0-3.12-1.12-6.08-3.12-8.4a26.54 26.54 0 0 1-6.84 11.52C66 52.2 59.2 55.04 51.96 55.04H39.44c-.48 0-.92.36-1 .88L35.4 77.04c-.28 2-1.44 3.76-3.12 4.84Z"
    />
  </svg>
);

export const ChevronDownArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 7.2c-.7 0-1.4.27-1.93.8l-6.52 6.52c-.29.29-.29.77 0 1.06.29.29.77.29 1.06 0l6.52-6.52c.48-.48 1.26-.48 1.74 0l6.52 6.52c.29.29.77.29 1.06 0 .29-.29.29-.77 0-1.06L13.93 8c-.53-.53-1.23-.8-1.93-.8Z"
    />
  </svg>
);

export const CardPaymentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={96}
    height={96}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M76 53.44H8c-1.64 0-3-1.36-3-3s1.36-3 3-3h68c1.64 0 3 1.36 3 3s-1.36 3-3 3Z"
    />
    <path
      fill="currentColor"
      d="M60.88 87H23.12C9.4 87 5 82.64 5 69.08V41.12C5 30.36 7.44 24 19.84 23.28c1.04-.04 2.12-.08 3.28-.08h37.76C74.6 23.2 79 27.56 79 41.12v28.6C78.84 82.8 74.44 87 60.88 87ZM23.12 29.2c-1.08 0-2.08.04-3 .08-7.16.44-9.12 1.96-9.12 11.84v27.96C11 79.32 12.68 81 23.12 81h37.76c10.32 0 12-1.6 12.12-11.32V41.12c0-10.24-1.68-11.92-12.12-11.92H23.12Z"
    />
    <path
      fill="currentColor"
      d="M76 72.72c-.76 0-1.52-.28-2.04-.8-.6-.56-.96-1.36-.96-2.2v-28.6c0-10.24-1.68-11.92-12.12-11.92H23.12c-1.08 0-2.08.04-3 .08-.8.04-1.6-.28-2.2-.84-.6-.56-.92-1.36-.92-2.2C17.16 13.2 21.56 9 35.12 9h37.76C86.6 9 91 13.36 91 26.92v27.96C91 65.64 88.56 72 76.16 72.72H76ZM23.12 23.2h37.76C74.6 23.2 79 27.56 79 41.12V66.4c4.64-.84 6-3.24 6-11.52V26.92C85 16.68 83.32 15 72.88 15H35.12c-9.12 0-11.48 1.24-12 8.2ZM27.842 74.24h-6.88c-1.64 0-3-1.36-3-3s1.36-3 3-3h6.88c1.64 0 3 1.36 3 3s-1.32 3-3 3ZM50.199 74.24h-13.76c-1.64 0-3-1.36-3-3s1.36-3 3-3h13.76c1.64 0 3 1.36 3 3s-1.32 3-3 3Z"
    />
  </svg>
);

export const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={17}
    fill="none"
    {...props}
  >
    <g stroke="#fff" clipPath="url(#a)">
      <path d="M19.996 1H2.004C1.45 1 1 1.376 1 1.839V15.16c0 .463.45.839 1.004.839h17.992c.555 0 1.004-.376 1.004-.839V1.84C21 1.376 20.55 1 19.996 1Z" />
      <path d="m1 1 10.425 7L21 1.23" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v17H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm-.75 6c0-.41.34-.75.75-.75s.75.34.75.75v5c0 .41-.34.75-.75.75s-.75-.34-.75-.75V8Zm1.67 8.38c-.05.13-.12.23-.21.33-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21c-.09-.1-.16-.2-.21-.33A.995.995 0 0 1 11 16c0-.13.03-.26.08-.38s.12-.23.21-.33c.1-.09.21-.16.33-.21a1 1 0 0 1 .76 0c.12.05.23.12.33.21.09.1.16.21.21.33.05.12.08.25.08.38s-.03.26-.08.38Z"
    />
  </svg>
);

export const ProfileIcon2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={31}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      d="M24.439 7.928 17.014 3.64a3.954 3.954 0 0 0-3.938 0L5.664 7.928c-1.213.7-1.963 2-1.963 3.412v8.55c0 1.4.75 2.7 1.963 3.413l7.425 4.287c1.212.7 2.712.7 3.937 0l7.425-4.287c1.213-.7 1.963-2 1.963-3.413v-8.55a3.99 3.99 0 0 0-1.975-3.412ZM15.05 9.79c1.613 0 2.913 1.3 2.913 2.913 0 1.612-1.3 2.912-2.913 2.912a2.907 2.907 0 0 1-2.912-2.912c0-1.6 1.3-2.913 2.912-2.913Zm3.35 11.65h-6.7c-1.012 0-1.6-1.125-1.037-1.962.85-1.263 2.5-2.113 4.387-2.113 1.888 0 3.538.85 4.388 2.113.562.825-.038 1.962-1.038 1.962Z"
    />
  </svg>
);

export const ProfileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={44}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M39.703 22c0-9.643-7.857-17.5-17.5-17.5-9.642 0-17.5 7.857-17.5 17.5 0 5.075 2.188 9.642 5.653 12.845 0 .017 0 .017-.018.035.175.175.385.315.56.472.105.088.193.176.298.245.315.263.665.508.997.753.123.087.228.157.35.245.333.227.683.438 1.05.63.123.07.263.157.385.227.35.193.718.368 1.103.526.14.07.28.14.42.192.385.157.77.297 1.155.42.14.052.28.105.42.14.42.123.84.228 1.26.333.122.034.245.07.385.087.49.105.98.175 1.487.227.07 0 .14.018.21.035.595.053 1.19.088 1.785.088s1.19-.035 1.768-.087c.07 0 .14-.018.21-.035.507-.053.997-.123 1.487-.228.123-.017.245-.07.385-.087.42-.105.858-.193 1.26-.333.14-.052.28-.105.42-.14.385-.14.788-.263 1.155-.42.14-.053.28-.123.42-.193.368-.157.735-.332 1.103-.524.14-.07.262-.158.385-.228.35-.21.7-.403 1.05-.63.122-.07.227-.157.35-.245.35-.245.682-.49.997-.753.105-.087.193-.175.298-.245.192-.157.385-.314.56-.472 0-.018 0-.018-.018-.035 3.483-3.203 5.67-7.77 5.67-12.845Zm-8.855 8.698c-4.742-3.186-12.512-3.186-17.29 0a8.45 8.45 0 0 0-1.925 1.75C8.973 29.752 7.328 26.06 7.328 22c0-8.207 6.668-14.875 14.875-14.875 8.208 0 14.875 6.668 14.875 14.875 0 4.06-1.645 7.753-4.305 10.447a8.098 8.098 0 0 0-1.925-1.75Z"
    />
    <path
      fill="currentColor"
      d="M22.203 13.127a6.565 6.565 0 0 0-6.562 6.562c0 3.553 2.782 6.44 6.475 6.546H22.43a6.55 6.55 0 0 0 6.335-6.546 6.565 6.565 0 0 0-6.563-6.562Z"
    />
  </svg>
);

export const WarehousingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43}
    height={35}
    fill="none"
    {...props}
  >
    <path
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1.776 33.983h38.46M24.083 33.983V3.081c0-.373-.15-.73-.419-.993a1.44 1.44 0 0 0-1.01-.412H5.512c-.38 0-.743.148-1.01.412-.268.263-.419.62-.419.993v30.902"
    />
    <path
      fill="#70B649"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M24.082 10.907h9.637a4.21 4.21 0 0 1 4.209 4.21v18.097H24.082V10.908Z"
    />
    <path
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.237 8.6h5.384M1.006 30.906V20.137M41.006 23.214V12.445M26.39 7.83h5.384M12.545 20.137h5.384M10.237 27.06h5.384M29.467 27.06h3.077M29.467 20.137h3.077"
    />
  </svg>
);

export const LocalShippingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      stroke="#121212"
      strokeWidth={2}
      d="M30.145 12.51v-7.5h1.166a9.821 9.821 0 0 1 9.079 6.074l.188.456a.702.702 0 0 1-.648.97h-9.785Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1.812 17.51h28.333M32.645 30.842a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
    />
    <path
      fill="#70B649"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.979 30.842a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M27.645 25.01H15.98M5.937 25.01H3.228c-.376 0-.736-.154-1.002-.428a1.48 1.48 0 0 1-.414-1.031V3.134c0-.387.149-.758.414-1.031a1.396 1.396 0 0 1 1.002-.427h26.917v19.542"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M30.145 12.509H41.81V23.62c0 .368-.153.722-.427.982-.273.26-.644.407-1.031.407H37.54M5.145 11.99V5.01M20.145 14.176h6.98M20.145 28.262h2.5"
    />
  </svg>
);

export const AirFreightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.09 24.545h20M19.586 16.205l8.655-5.21M5.849 14.762l3.354 3.354M1 13.55l3.354 3.354M9.235 13.399l5.273 5.058a4.388 4.388 0 0 0 5.315.616L41 6.463l-2.602-3.267a4.456 4.456 0 0 0-2.678-1.61 4.386 4.386 0 0 0-3.056.564l-6.21 3.74-8.393-2.866-2.308 1.017a1.123 1.123 0 0 0-.44.347 1.16 1.16 0 0 0-.176 1.086c.063.182.168.344.308.473l4.294 3.955-3.916 2.293-3.917-1.72-2.35 1.032c-.17.075-.32.193-.436.343a1.16 1.16 0 0 0-.183 1.075c.06.18.161.343.298.473v0Z"
    />
    <path
      fill="#70B649"
      stroke="#121212"
      strokeWidth={2}
      d="M38.879 3.94c-3.363 2.64-6.682 2.972-9.091 0l1.893-1.231a5.38 5.38 0 0 1 7.198 1.23Z"
    />
  </svg>
);

export const ProjectExhibitionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={48}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.067 33.6V12.133M34.733 12.133V33.6M13.267 22.867v3.066M19.4 21.334v4.6M25.533 19.8v6.134M36.267 6H2.533C1.687 6 1 6.686 1 7.533V10.6c0 .847.687 1.533 1.533 1.533h33.734c.846 0 1.533-.686 1.533-1.533V7.533C37.8 6.686 37.114 6 36.267 6Z"
    />
    <path
      fill="#70B649"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M36.267 6H2.533C1.687 6 1 6.686 1 7.533V10.6c0 .847.687 1.533 1.533 1.533h33.734c.846 0 1.533-.686 1.533-1.533V7.533C37.8 6.686 37.114 6 36.267 6Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.12 46.036c-1.442-1.048-2.09-3.583-.469-5.672M1 1h16.8M41 14.6v8"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.4 33.6v6.133"
    />
    <path
      fill="#70B649"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.4 45.867a3.067 3.067 0 1 0 0-6.134 3.067 3.067 0 0 0 0 6.134Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 33.6h36.8"
    />
  </svg>
);

export const CustomerClearanceIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.958 20.71c5.443 0 9.855-4.412 9.855-9.855C27.813 5.412 23.401 1 17.958 1c-5.443 0-9.855 4.412-9.855 9.855 0 5.443 4.412 9.855 9.855 9.855Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.865 27.28a18.396 18.396 0 0 1 28.185 0"
    />
    <path
      fill="#fff"
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M37.536 28.101h-7.391v-13.14h7.391v13.14Z"
    />
    <path
      fill="#70B649"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M37.536 19.888h-7.391v-4.927h7.391v4.927Z"
    />
    <path
      stroke="#141414"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M35.073 18.246H32.61M35.073 22.352H32.61"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M40 20.71v6.986M4.858 16.177c-.633-.835-2.195-3.613-.987-8.832M25.494 11.877c-.035.84-.5 3.36-4.006 6.024"
    />
  </svg>
);

export const SeaTransportIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={46}
    fill="none"
    {...props}
  >
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 6V1M41 29.334C37.958 39.959 23.875 43.666 21.375 44.25a1.521 1.521 0 0 1-.75 0C18.125 43.667 4.042 39.96 1 29.334v-7.125a1.667 1.667 0 0 1 1.146-1.584L20.479 14.5c.34-.104.703-.104 1.042 0l18.333 6.125A1.667 1.667 0 0 1 41 22.21v7.125Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.38 38.847c-1.979-.455-13.121-3.35-15.528-11.645"
    />
    <path
      fill="#70B649"
      stroke="#121212"
      strokeWidth={2}
      d="M5.852 10.061v8.892l15.042-5.337 15.042 5.337v-8.892a4.21 4.21 0 0 0-4.21-4.209H10.062a4.21 4.21 0 0 0-4.209 4.21Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 31V14.416M25.262 30.113V23.32M9.735 14.586V9.734M39.817 16.527V7.793"
    />
  </svg>
);

export const NotificationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      d="M16.475 9.704c-.61-.711-.886-1.327-.886-2.374v-.356c0-1.363-.314-2.242-.996-3.12-1.052-1.365-2.823-2.188-4.556-2.188h-.074c-1.697 0-3.412.785-4.482 2.094-.72.896-1.07 1.813-1.07 3.214v.356c0 1.047-.259 1.663-.886 2.374-.46.523-.608 1.197-.608 1.925 0 .73.24 1.42.72 1.982a3.776 3.776 0 0 0 2.416 1.177c1.31.15 2.619.206 3.948.206 1.327 0 2.636-.094 3.947-.206a3.776 3.776 0 0 0 2.416-1.177c.48-.562.72-1.253.72-1.982 0-.728-.148-1.402-.61-1.925"
    />
    <path
      fill="#70B649"
      d="M11.674 16.024c-.417-.09-2.955-.09-3.372 0-.356.082-.741.273-.741.693.02.4.255.754.58.978H8.14c.42.328.913.536 1.429.61.275.038.554.037.84 0a3.03 3.03 0 0 0 1.427-.61c.324-.224.559-.578.58-.978 0-.42-.386-.611-.742-.693"
      opacity={0.4}
    />
  </svg>
);

export const TruckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      d="M37.625 27.125c.49 0 .875.385.875.875v1.75A5.243 5.243 0 0 1 33.25 35c0-2.888-2.363-5.25-5.25-5.25s-5.25 2.362-5.25 5.25h-3.5c0-2.888-2.363-5.25-5.25-5.25S8.75 32.112 8.75 35a5.243 5.243 0 0 1-5.25-5.25v-3.5c0-.962.787-1.75 1.75-1.75h16.625a4.377 4.377 0 0 0 4.375-4.375V10.5c0-.963.788-1.75 1.75-1.75h1.47c1.26 0 2.415.682 3.045 1.768l1.12 1.96a.441.441 0 0 1-.385.647 4.377 4.377 0 0 0-4.375 4.375v5.25a4.377 4.377 0 0 0 4.375 4.375h4.375Z"
    />
    <path
      fill="#70B649"
      d="M14 38.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM28 38.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM38.5 21.927V24.5h-5.25c-.962 0-1.75-.788-1.75-1.75V17.5c0-.962.788-1.75 1.75-1.75h2.258l2.537 4.445c.297.525.455 1.12.455 1.732ZM22.89 3.5H9.957a6.46 6.46 0 0 0-6.335 5.215h7.648c.665 0 1.19.543 1.19 1.207a1.18 1.18 0 0 1-1.19 1.19H3.5v2.415h4.55a1.21 1.21 0 0 1 1.207 1.208c0 .665-.542 1.19-1.207 1.19H3.5v2.415h1.348a1.21 1.21 0 0 1 1.207 1.207c0 .665-.542 1.19-1.207 1.19H3.5v.403c0 .962.787 1.75 1.75 1.75h16.012a3.241 3.241 0 0 0 3.238-3.238V5.11c0-.893-.718-1.61-1.61-1.61Z"
    />
    <path
      fill="#70B649"
      d="M3.623 8.715H1.644A1.21 1.21 0 0 0 .437 9.922c0 .665.543 1.19 1.208 1.19H3.5V9.957c0-.42.053-.84.123-1.242ZM3.237 13.527H1.645a1.21 1.21 0 0 0-1.208 1.208c0 .665.543 1.19 1.208 1.19H3.5v-2.398h-.263ZM3.237 18.34H1.645a1.21 1.21 0 0 0-1.208 1.207c0 .665.543 1.19 1.208 1.19H3.5V18.34h-.263Z"
    />
  </svg>
);

export const LinkedinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      d="M20.01.125H1.858C.867.125 0 .839 0 1.819V20.01c0 .986.867 1.864 1.858 1.864h18.147c.997 0 1.745-.884 1.745-1.864V1.82c.006-.98-.748-1.694-1.74-1.694ZM6.742 18.255H3.626V8.567h3.116v9.688ZM5.292 7.094H5.27c-.998 0-1.643-.743-1.643-1.672 0-.946.662-1.67 1.682-1.67s1.643.719 1.666 1.67c0 .93-.647 1.672-1.683 1.672Zm12.838 11.16h-3.116v-5.297c0-1.269-.454-2.136-1.581-2.136-.861 0-1.371.583-1.598 1.15-.085.204-.108.482-.108.766v5.518H8.612V8.567h3.115v1.348c.454-.646 1.162-1.575 2.81-1.575 2.046 0 3.593 1.348 3.593 4.255v5.66Z"
    />
  </svg>
);

export const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      d="M22.221 2.745c-.805.35-1.657.58-2.53.684a4.36 4.36 0 0 0 1.939-2.396 8.849 8.849 0 0 1-2.799 1.05A4.435 4.435 0 0 0 15.615.711c-2.435 0-4.405 1.94-4.405 4.331-.001.333.037.665.114.988a12.563 12.563 0 0 1-9.08-4.528 4.275 4.275 0 0 0-.6 2.177c0 1.502.785 2.83 1.969 3.607a4.364 4.364 0 0 1-2.003-.54v.053c0 2.1 1.52 3.849 3.534 4.246-.379.101-.77.152-1.16.152-.279 0-.556-.026-.829-.08.56 1.72 2.188 2.972 4.118 3.008A8.932 8.932 0 0 1 .75 15.918a12.473 12.473 0 0 0 6.743 1.957c8.112 0 12.544-6.602 12.544-12.328 0-.187-.005-.375-.014-.558a8.877 8.877 0 0 0 2.198-2.244Z"
    />
  </svg>
);

export const FacebookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#70B649"
      fillRule="evenodd"
      d="M23.22 11.232c0-5.948-5.148-10.771-11.5-10.771C5.37.46.22 5.284.22 11.232c0 5.377 4.206 9.833 9.704 10.642v-7.527h-2.92v-3.114h2.92V8.858c0-2.699 1.717-4.19 4.344-4.19 1.258 0 2.575.21 2.575.21v2.65h-1.451c-1.428 0-1.875.83-1.875 1.684v2.02h3.19l-.51 3.114h-2.68v7.528c5.5-.809 9.704-5.265 9.704-10.643Z"
      clipRule="evenodd"
    />
  </svg>
);

export const LocationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={41}
    fill="none"
    {...props}
  >
    <circle cx={20.5} cy={20.5} r={20.5} fill="#70B649" />
    <path
      fill="#fff"
      d="M20.825 19.524c.358 0 .665-.128.92-.383s.382-.561.382-.919-.127-.664-.382-.92a1.253 1.253 0 0 0-.92-.382c-.358 0-.664.128-.919.383a1.253 1.253 0 0 0-.382.919c0 .358.127.664.382.92.255.254.561.382.92.382Zm0 4.783c1.324-1.215 2.305-2.319 2.945-3.311.64-.992.96-1.874.96-2.644 0-1.182-.377-2.15-1.13-2.904-.755-.754-1.68-1.13-2.775-1.13s-2.02.376-2.774 1.13c-.753.754-1.13 1.722-1.13 2.904 0 .77.32 1.651.96 2.644.64.992 1.621 2.096 2.944 3.31Zm0 1.725c-1.746-1.486-3.05-2.867-3.912-4.141-.863-1.275-1.294-2.454-1.294-3.539 0-1.627.523-2.923 1.57-3.888 1.047-.966 2.259-1.448 3.636-1.448 1.378 0 2.59.482 3.637 1.448 1.046.965 1.57 2.261 1.57 3.888 0 1.085-.431 2.264-1.294 3.539-.862 1.274-2.166 2.655-3.913 4.14Z"
    />
  </svg>
);
