import { Spinner } from "@lib/Loader";
import React from "react";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  rounded?: boolean;
  className?: string;
  isLoading?: boolean;
}

export const Button = ({ label, className, isLoading, ...props }: IProps) => {
  return (
    <button
      {...props}
      className={`w-full text-sm flex items-center justify-center bg-primary-500 border-none p-5 text-white font-semibold ${
        props.rounded ? "rounded-full" : "rounded-lg"
      } ${className} ${props.disabled ? "cursor-not-allowed opacity-60" : ""}`}
    >
      {isLoading ? <Spinner /> : label}
    </button>
  );
};
