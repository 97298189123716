import React from "react";

export const TermsOfUse = () => {
  return (
    <div>
      <div className="w-full bg-header_overflow bg-no-repeat bg-center bg-cover h-[15vh] flex items-center">
        <div className="w-[90%] 2xl:w-[60%] mx-auto flex items-center justify-end">
          <h1 className="text-2xl text-white">Terms Of Use</h1>
        </div>
      </div>
      <div className="w-[90%] 2xl:w-[60%] mx-auto px-4 py-8">
        <p>
          Welcome to Kadaa Limited! Before accessing or using our website,
          please take a moment to carefully review the following Terms of Use
          ("Terms"). These Terms govern your use of our website and services and
          constitute a legally binding agreement between you and Kadaa Limited.
          By accessing or using our website, you signify your acceptance and
          agreement to be bound by these Terms. If you do not agree with any
          part of these Terms, please refrain from using our website.
        </p>
        <ol className="list-decimal">
          <li>
            <p>
              <b>Website Use and Content:</b>
            </p>
          </li>
          <ul className="ml-4 list-disc">
            <li>
              <p>
                <b>Eligibility:</b>
              </p>
              You must be at least 18 years old or the legal age in your
              jurisdiction to use our website. By accessing our website, you
              confirm that you meet this requirement.
            </li>
            <li>
              <p>
                <b>Limited License:</b>
              </p>
              We grant you a limited, non-exclusive, and non-transferable
              license to access and use our website for personal, non-commercial
              purposes. You agree not to use the website for any unlawful or
              unauthorized purposes.
            </li>
            <li>
              <p>
                <b>Intellectual Property:</b>
              </p>
              All content, materials, and intellectual property displayed on our
              website, including but not limited to logos, trademarks, images,
              and text, are the property of Kadaa Limited and protected by
              applicable intellectual property laws. You agree not to reproduce,
              modify, distribute, or exploit any content without our prior
              written consent.
            </li>
          </ul>
          <li>
            <p>
              <b>User Conduct</b>
            </p>
          </li>
          <ul className="ml-4 list-disc">
            <li>
              <p>
                <b>Prohibited Activities:</b>
              </p>
              You agree not to engage in any conduct that may disrupt or
              interfere with the proper functioning of our website, compromise
              its security, or infringe upon the rights of others. This includes
              but is not limited to hacking, transmitting malware, spamming, or
              engaging in any unlawful activities.
            </li>
            <li>
              <p>
                <b>User-generated Content:</b>
              </p>
              If you post or submit any content on our website, you warrant that
              you have the right to do so and that the content does not violate
              any third-party rights or applicable laws.
            </li>
          </ul>
          <li>
            <p>
              <b>Privacy and Data Protection:</b>
            </p>
            We understand the importance of safeguarding your personal
            information. We may share your information under the following
            circumstances:
          </li>
          <ul className="ml-4 list-disc">
            <li>
              <p>
                <b>Privacy Policy:</b>
              </p>
              Your use of our website is subject to our Privacy Policy, which
              outlines how we collect, use, disclose, and safeguard your
              personal information. By using our website, you consent to the
              practices described in the Privacy Policy.
            </li>
          </ul>
          <li>
            <p>
              <b>Third-party Links:</b>
            </p>
            Our website may contain links to third-party websites. These links
            are provided for your convenience only, and we do not endorse or
            assume any responsibility for the content or practices of these
            third-party websites. Your use of linked websites is subject to
            their respective terms and policies.
          </li>
          <li>
            <p>
              <b>Disclaimer of Warranties:</b>
            </p>
          </li>
          <ul className="ml-4 list-disc">
            <li>
              <p>
                <b>Website "As Is":</b>
              </p>
              Our website is provided on an "as-is" and "as available" basis. We
              make no warranties or representations regarding the availability,
              accuracy, reliability, or completeness of our website or its
              content.
            </li>
            <li>
              <p>
                <b>Limitation of Liability:</b>
              </p>
              To the fullest extent permitted by law, Kadaa Limited shall not be
              liable for any direct, indirect, incidental, special,
              consequential, or exemplary damages arising from your use of our
              website.
            </li>
          </ul>
          <li>
            <p>
              <b>Indemnification:</b>
            </p>
            You agree to indemnify, defend, and hold Kadaa Limited, its
            officers, directors, employees, and affiliates harmless from any
            claims, damages, liabilities, or expenses arising from your breach
            of these Terms or your use of our website.
          </li>
          <li>
            <p>
              <b>Modifications and Termination:</b>
            </p>
            We reserve the right to modify or terminate our website or any part
            thereof at any time without prior notice. We may also update these
            Terms from time to time, and your continued use of our website
            following any such changes constitutes your acceptance of the
            updated Terms.
          </li>
          <li>
            <p>
              <b>Contact Us:</b>
            </p>
            If you have any questions, comments, or concerns about these Terms
            or our website, please contact us at [info@kadaalimited.com / 03301337944].
          </li>
        </ol>
        <p>
          Thank you for choosing Kadaa Limited. We hope you enjoy your
          experience on our website!
        </p>
        <p className="font-bold">
          #TermsOfUse #KadaaLimited #WebsiteUsage #UserAgreement
        </p>
      </div>
    </div>
  );
};
