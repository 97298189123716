import React from "react";

export const OurGoalsPage = () => {
  return (
    <div>
      <div className="w-full bg-header_overflow bg-no-repeat bg-center bg-cover h-[15vh] flex items-center">
        <div className="w-[90%] 2xl:w-[60%] mx-auto flex items-center justify-end">
          <h1 className="text-2xl text-white">Our Goals</h1>
        </div>
      </div>

      <div className="w-[90%] 2xl:w-[60%] mx-auto px-4 py-8">
        <p className="mt-4">
          At Kadaa Limited, we embark on a remarkable journey towards a shared
          vision - to be the leading force in sustainable logistics solutions
          that redefine the industry's environmental impact. Our goal is to
          pioneer a better tomorrow, where logistics operations not only deliver
          excellence but also contribute to a healthier, greener planet for
          generations to come.
        </p>
        <p className="mt-4">
          <b>Commitment to Sustainability:</b> Our commitment to sustainability
          is the driving force behind everything we do. We recognize the urgent
          need to address environmental challenges and understand the critical
          role that logistics plays in shaping a sustainable future. As a
          responsible logistics company, we are dedicated to minimizing our
          carbon footprint, conserving natural resources, and fostering
          eco-conscious practices throughout our operations.
        </p>
        <p className="mt-4">
          <b>The Power of Electric Mobility:</b> At Kadaa Limited, we have
          harnessed the transformative power of electric mobility to
          revolutionize logistics. Our fleet of electric vehicles (EVs) stands
          at the forefront of eco-friendly transportation, reducing harmful
          emissions and contributing to cleaner air for our communities. By
          embracing EVs, we align our services with the global commitment to
          combat climate change and preserve the beauty of our planet.
        </p>
        <p className="mt-4">
          <b>Driving Innovation for a Greener Tomorrow:</b> Innovation is at the
          heart of our sustainable journey. Through cutting-edge technologies
          and smart logistics practices, we optimize routes, reduce energy
          consumption, and ensure the efficient use of resources. Our continuous
          pursuit of sustainable innovation enables us to deliver logistics
          solutions that not only meet our clients' needs but also respect the
          delicate balance of our environment.
        </p>
        <p className="mt-4">
          <b>Partners in Sustainability:</b> As advocates of sustainability, we
          firmly believe in the power of collaboration. We forge strong
          partnerships with like-minded businesses, organizations, and customers
          who share our commitment to sustainability. Together, we form a
          powerful force, collectively working towards a greener world and
          inspiring positive change within the logistics industry.
        </p>
        <p className="mt-4">
          <b>A Sustainable Logistics Portfolio:</b> Our goal extends beyond
          delivering goods; it encompasses delivering on the promise of
          sustainability. From electric vehicle deliveries to green warehousing
          and carbon-neutral freight forwarding, our sustainable logistics
          portfolio encompasses a wide range of services tailored to your
          eco-conscious needs.
        </p>
        <p className="mt-4">
          <b>Empowering Businesses for a Greener Future:</b> We are not just a
          logistics company; we are a catalyst for change. By partnering with
          Kadaa Limited, businesses of all sizes and industries can actively
          contribute to a greener future. Together, we create a ripple effect of
          sustainability that resonates far beyond the confines of logistics.
        </p>
        <p className="mt-4">
          <b>Join the Sustainable Journey:</b> Kadaa Limited invites you to be
          part of our sustainable journey. Together, let us embark on a path
          that elevates logistics to new heights of environmental responsibility
          and propels us towards a brighter and cleaner tomorrow. Contact us
          today and be a part of our commitment to sustainable logistics.
        </p>
      </div>
    </div>
  );
};
