import React from "react";

export const PrivacyPolicyPage = () => {
  return (
    <div>
      <div className="w-full bg-header_overflow bg-no-repeat bg-center bg-cover h-[15vh] flex items-center">
        <div className="w-[90%] 2xl:w-[60%] mx-auto flex items-center justify-end">
          <h1 className="text-2xl text-white">Privacy Policy</h1>
        </div>
      </div>
      <div className="w-[90%] 2xl:w-[60%] mx-auto px-4 py-8">
        <p>
          Welcome to Kadaa Limited's Privacy Policy. Your privacy is of utmost
          importance to us, and we are dedicated to protecting your personal
          information. This Privacy Policy outlines how we collect, use,
          disclose, and safeguard your data when you interact with our website
          and services. By accessing or using our website, you signify your
          consent to the practices described in this Privacy Policy.
        </p>
        <ol className="list-decimal">
          <li>
            <p>
              <b>Information We Collect:</b>
            </p>
            We may collect certain personal information from you when you visit
            our website or use our services. The types of information we collect
            include:
          </li>
          <ul className="ml-4 list-disc">
            <li>
              <p>
                <b>Contact Information:</b>
              </p>
              We may collect your name, email address, phone number, and postal
              address to facilitate communication and provide personalized
              services.
            </li>
            <li>
              <p>
                <b>Log Data:</b>
              </p>
              Our website automatically collects certain information when you
              visit, including your IP address, browser type, operating system,
              pages viewed, and access times. This information helps us analyze
              trends and improve our website's performance.
            </li>
            <li>
              <p>
                <b>Cookies and Similar Technologies:</b>
              </p>
              We use cookies and similar technologies to enhance your browsing
              experience, understand how you use our website, and improve our
              services. Cookies are small text files stored on your device that
              help us remember your preferences and deliver a personalized user
              experience.
            </li>
          </ul>
          <li>
            <p>
              <b>How We Use Your Information:</b>
            </p>
            We use the collected information for the following purposes:
          </li>
          <ul className="ml-4 list-disc">
            <li>
              <p>
                <b>Service Delivery:</b>
              </p>
              We use your information to process your requests, respond to
              inquiries, and provide you with our services. This includes
              facilitating deliveries, tracking shipments, and ensuring the
              smooth functioning of our logistics operations.
            </li>
            <li>
              <p>
                <b>Communication:</b>
              </p>
              We may use your contact information to communicate with you
              regarding important updates, promotions, and information about our
              services that may be of interest to you. You have the option to
              opt-out of marketing communications at any time.
            </li>
            <li>
              <p>
                <b>Website Improvement:</b>
              </p>
              Analyzing user trends and behavior helps us identify areas for
              improvement on our website. By understanding how users interact
              with our platform, we can optimize navigation, content, and
              overall user experience.
            </li>
            <li>
              <p>
                <b>Legal Compliance:</b>
              </p>
              In certain circumstances, we may be required to disclose your
              information to comply with applicable laws, regulations, or legal
              processes.
            </li>
          </ul>
          <li>
            <p>
              <b>Disclosure of Your Information:</b>
            </p>
            We understand the importance of safeguarding your personal
            information. We may share your information under the following
            circumstances:
          </li>
          <ul className="ml-4 list-disc">
            <li>
              <p>
                <b>Third-Party Service Providers:</b>
              </p>
              To enhance our services and optimize our website, we may engage
              reputable third-party service providers. These partners are
              contractually obligated to handle your information with the utmost
              confidentiality and only for the purposes specified by us.
            </li>
            <li>
              <p>
                <b>Legal Obligations:</b>
              </p>
              We may disclose your information to comply with any applicable
              laws, regulations, court orders, or governmental requests.
            </li>
            <li>
              <p>
                <b>Consent:</b>
              </p>
              We will seek your explicit consent before sharing your personal
              information with any third party for purposes beyond those
              outlined in this Privacy Policy.
            </li>
          </ul>
          <li>
            <p>
              <b>Data Security:</b>
            </p>
            We take data security seriously and implement reasonable measures to
            protect your personal information from unauthorized access,
            disclosure, or alteration. These security measures include
            administrative, technical, and physical safeguards. However, it is
            essential to recognize that no data transmission over the internet
            or electronic storage method is entirely secure. Therefore, while we
            strive to protect your information, we cannot guarantee its absolute
            security.
          </li>
          <li>
            <p>
              <b>Cookies and Tracking Technologies:</b>
            </p>
            We respect your rights and choices concerning your personal
            information. You have the right to access, update, or delete your
            data. If you wish to exercise these rights or have any concerns
            about your information, please contact us using the information
            provided in Section 9.
          </li>
          <li>
            <p>
              <b>Children's Privacy:</b>
            </p>
            Our website is not directed to children under the age of 16, and we
            do not knowingly collect personal information from individuals under
            this age without parental consent.
          </li>
          <li>
            <p>
              <b>Changes to this Privacy Policy:</b>
            </p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or legal requirements. We will notify you
            of any significant changes through our website or other appropriate
            means.
          </li>
          <li>
            <p>
              <b>Contact Us:</b>
            </p>
            If you have any questions, comments, or concerns about this Privacy
            Policy or our data practices, please contact us using the following
            information:
          </li>
          <p>Email: privacypolicy@kadaalimited.com</p>
        </ol>
        <p>
          At Kadaa Limited, your privacy and data security are of paramount
          importance, and we remain committed to upholding the highest standards
          of privacy protection while delivering exceptional logistics services.
          Thank you for entrusting us with your personal information.
        </p>
      </div>
    </div>
  );
};
