import { CardPaymentIcon, StripeIcon } from "@assets/icons";
import { ICreateShipment } from "@network/interface";
import { FormikErrors } from "formik";

interface IProps {
  handleChange: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: ICreateShipment;
  errors: FormikErrors<ICreateShipment>;
}

export const PaymentOptions = ({ errors, setFieldValue, values }: IProps) => {
  const paymentOptions = [
    { title: "Stripe", icon: <StripeIcon />, method: "STRIPE" },
    // { title: "Paypal", icon: <PaypalIcon />, method: "PAYPAL" },
    {
      title: "Credit Card",
      icon: <CardPaymentIcon />,
      method: "CREDIT_CARD",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold text-center">
        Awesome, we are almost there!
      </h1>
      <p className="text-base text-gray-400 text-center">
        Please select your preferred payment option
      </p>
      <div className='flex flex-col items-center'>
        <div className="flex items-center justify-start md:justify-center mx-auto md:mx-0 gap-5 my-8 flex-wrap">
          {paymentOptions.map((method) => (
            <div
              onClick={() => {
                setFieldValue("preferred_payment_method", method.method);
              }}
              className={`w-[8rem] md:w-[10rem] h-[12rem] md:h-[13rem] p-3 md:p-8 rounded-md shadow-lg flex flex-col items-center justify-center gap-3 cursor-pointer text-gray-400 ${
                values.preferred_payment_method === method.method
                  ? "border border-primary-500 shadow-primary-500 text-primary-500"
                  : ""
              }`}
            >
              {method.icon}
              <p>{method.title}</p>
            </div>
          ))}
        </div>
        {errors?.preferred_payment_method ? (
          <small className="text-sm text-center text-red-300">
            {errors?.preferred_payment_method}
          </small>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
